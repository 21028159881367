import React, { useState } from "react";
import ReactDom from "react-dom";
import "../../../css/components/_unSupportedBrowser.scss";
import CrossVideored from "../../../images/no-video-(5).png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Img from "../../../images/Icon-awesome-copy.png";
import Tooltip from "@material-ui/core/Tooltip";
function BrowsweUnsupported() {
  const [Copy, setCopy] = useState(false);
  console.log("====================================");
  console.log(window);
  console.log("====================================");
  return ReactDom.createPortal(
    <div className="unsupportWrappre">
      <img className="cp_image_red" src={CrossVideored} alt="" />
      <p>uh ho, unSupported browser!</p>
      <span>
        Looks like you are using an unsupported browser,please <br /> press the
        button below to copy this room link and paste
        <br /> it into your Safari browser
      </span>
      <CopyToClipboard
        text={`${window.location.href}`}
        onCopy={() => setCopy(true)}
      >
        <Tooltip title={Copy ? "Copied" : "Copy"} placement="top-start">
          <button>
            <img src={Img} alt="" />
            <span>Copy room link</span>
          </button>
        </Tooltip>
      </CopyToClipboard>
    </div>,
    document.getElementById("PoratunsupportedError")
  );
}

export default BrowsweUnsupported;
