/** @format */

import React, { Component } from "react";
import * as Actions from "../../../servers/Telemed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-responsive-modal/styles.css";
import "../../../css/components/_TelemedMainDashBoard.scss";
import Img from "../../../images/copyLink.png";
import Img3 from "../../../images/Icon-awesome-clock.png";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import DrPermission from "./DrCameraPermession";
import { DoctorEndServer } from "../TelemedDoctorEnd/server";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CrossVideo from "../../../images/no-video(2).png";
import Loader from "../../utils/TransparentLoader";
import BrowsweUnsupported from "./BrowsweUnsupported";
import PatientInformation from "./PatientInformation";
import { ROOT_URL } from "../../../constants/config";
import CircularProgress from "@material-ui/core/CircularProgress";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svnack: false,
      Token: "",
      Data: {},
      Message: "",
      copied: false,
      Appoinment: [],
      PatientDetais: {},
      ExtraPAtientData: false,
      index: null,
      per: false,
      Loading: true,
      ExpireSnack: false,
      unsupportedBrowser: true,
      selectedIndex: null,
      totalAppointments: [],
      totalAppointmentsLoading: true,
    };
    const { dispatch } = props;
    this.boundActionCreators = bindActionCreators(Actions, dispatch);
    this.startcall = this.startcall.bind(this);
    this.Snacck = this.Snacck.bind(this);
    // const B = this
  }
  setPerState = (state) => {
    this.setState({
      per: state,
    });
  };
  //   onCloseModal = () => {
  //     let { dispatch } = this.props;
  //     dispatch(Actions.OpenModal(false));
  //   };

  Snacck = () => {
    let { dispatch } = this.props;
    dispatch(Actions.Patientend_Block_Send_message_to_Dr(true));
    // this.setState({ svnack: true }, () => {
    //   alert("dv")
    //   console.log(this.state.svnack, 'dealersOverallTotal1');
    // });
    // alert("Snack")
    // snack = true
  };
  startcall = (Data, Res) => {
    // alert("hellow World")
    console.log("sndvjndjvnsdjknsdjfndksjnfjsdR", Data);

    let { dispatch } = this.props;
    // when a call start. add user in room, and create room
    //     const config = {
    //   DrId: this.props.doctor._id ? this.props.doctor._id : "",
    //   identity: this.props.doctor.doctorName,
    //   room: this.props.doctor.roomName,
    // };
    // const data = await fetch('/video/token', {
    //   method: 'POST',
    //   body: JSON.stringify(config),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // }).then(res => res.json());
    // console.log

    // this.props.StartCall(Data);
    // const socket = io(`${ROOT_URL}`);
    // socket.on("StartCall", (data) => {
    // console.log("socketdata", data);
    let per = false;
    let classState = this.setPerState;
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(
        // navigator.permissions.query({ name: "camera" }).then(
        (permissionStatus) => {
          console.log("geolocation permission state is ", permissionStatus);
          if (permissionStatus.active) {
            // permis = true
            // console.log("MAIN : ", this.state)
            this.props.socket.emit("PatientEndStart", {
              PatientStart: true,
              id: Data,
            });
            // alert("Chnged")
            // classState(true)
          } else {
            dispatch(Actions.RoomTokenExpire(true));
            dispatch(Actions.isPermissionchecked(false));
          }
          permissionStatus.onchange = (e) => {
            console.log(
              "geolocation permission state has changed to ",
              e.currentTarget.active
            );
            if (e.currentTarget.active && this.props.StartCAll) {
              this.props.socket.emit("PatientEndStart", {
                PatientStart: true,
                id: Data,
              });
            }
          };
        }
      )
      .catch((error) => {
        console.log("dsfndsknfjsdnfjkdsnfjkndsjkfndsj", error);

        if (
          error.message ==
          "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
        ) {
          dispatch(Actions.RoomTokenExpire(true));
          dispatch(Actions.isPermissionchecked(false));
        } else {
          if (navigator.permissions != undefined) {
            navigator.permissions
              .query({ name: "video" })
              .then((permissionStatus) => {
                if (permissionStatus.state == "granted") {
                  this.props.socket.emit("PatientEndStart", {
                    PatientStart: true,
                    id: Data,
                  });
                } else if (permissionStatus.state == "denied") {
                  dispatch(Actions.RoomTokenExpire(true));
                  dispatch(Actions.isPermissionchecked(false));
                } else {
                  dispatch(Actions.RoomTokenExpire(true));
                  dispatch(Actions.isPermissionchecked(false));
                }
                permissionStatus.onchange = (e) => {
                  console.log(
                    "geolocation permission state has changed to ",
                    e.currentTarget.active
                  );
                  if (
                    e.currentTarget.state === "granted" &&
                    this.props.StartCAll
                  ) {
                    this.props.socket.emit("PatientEndStart", {
                      PatientStart: true,
                      id: Data,
                    });
                  } else {
                    dispatch(Actions.RoomTokenExpire(true));
                    dispatch(Actions.isPermissionchecked(false));
                  }
                };
              })
              .catch((error) => {
                console.log("dsfndsknfjsdnfjkdsnfjkndsjkfndsj", error);

                dispatch(Actions.RoomTokenExpire(true));
                dispatch(Actions.isPermissionchecked(false));
              });
          }
        }
      });

    // console.log("State : ", this.state.per);

    // if (permis == true) {
    // dispatch(Actions.StartCAll(false, Data))
    // this.props.socket.emit("PatientEndStart", { PatientStart: true, id: Data })
    // }
    // this.props.socket.emit("PatientEndStart", { PatientStart: false, id: Data })

    dispatch(Actions.isPermissionchecked(true));
    // dispatch(Actions.StartCAll(true, Data))
    // });
    this.props.socket.on("patientCallPermissionIssue", (data) => {
      // alert("fa")
      console.log("patientCallPermissionIssue", data);

      if (data.patientPermission == true) {
        // alert("dv")
        // this.state.Snack == true
        this.Snacck();
        console.log("yretre", this.state);
        dispatch(Actions.isPermissionchecked(false));
        dispatch(Actions.StartCAll(false));
      } else {
        // alert("v")
        dispatch(Actions.isPermissionchecked(false));
        dispatch(Actions.StartCAll(true, Data, Res));
      }
    });

    // const socket = io("http://localhost:3302");
  };
  time = () => {
    // alert("Time")
    this.setState({
      A: "A",
    });
  };
  async componentDidMount() {
    // navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    //   .then(function (stream) {
    //     console.log("here::", stream)
    //   })
    // this.props.socket.emit("DrUser", this.props.doctor);
    // Axios.get(`${ROOT_URL}/api/client/appointments/getAllAppointments`).then(
    //   (res) => {
    //     this.setState({
    //       Appoinment: res.data.appointmentList,
    //     });
    //   }
    // );
    const doctorData = localStorage.getItem("DrId");
    let Token = this.props.cookies.get("token");
    let practiceID = this.props.cookies.get("practiceID");

    let objetct = {
      token: Token,
      practiceID: practiceID,
    };
    const data = { seenByDoctor: JSON.parse(doctorData)?._id };
    let mythis = this;
    // this.setState({ totalAppointments: [] });
    fetch(ROOT_URL + "/api/client/appointments/TotalAppointmentsByDoctorID", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.stringify(objetct),
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(function (response) {
      console.log("Response from database: ", response);
      if (response.state >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json().then((data) => {
        if (data.TotalAppointmentsStatus === "failure") {
          // return
          mythis.setState({
            totalAppointments: [],
            totalAppointmentsLoading: true,
          });
        } else if (data.TotalAppointmentsStatus === "success") {
          mythis.setState({
            totalAppointments: data.appointmentList,
            totalAppointmentsLoading: false,
          });
        }
      });
    });

    let a = await localStorage.getItem("DrId");
    // console.log("dsv", JSON.parse(a));
    // if (a) {
    //   DoctorEndServer.authenticateDoctor({ Token: JSON.parse(a), Drid: true });
    // } else {
    //   // alert("dsvn")
    // }

    this.Interval = setInterval(() => {
      this.ExpireChexked();
    }, 3.6e6);

    this.props.socket.on("availablepatients", (data) => {
      console.log("dsvsdv", data);
      if (data?.length !== 0) {
        this.setState({
          Appoinment: data,
          Loading: false,
          ExtraPAtientData: false,
          index: null,
        });
      } else {
        this.setState({
          ExtraPAtientData: false,
          index: null,
          Appoinment: [],
          Loading: false,
        });
      }
    });
    this.TimeInterval = setInterval(() => {
      this.time();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.Interval);
    clearInterval(this.TimeInterval);
    // alert("Abc")
  }

  BrwsernotSupporter = (error) => {
    this.setState({
      unsupportedBrowser: error,
    });
  };

  Safricancel = () => {
    let { dispatch } = this.props;
    dispatch(Actions.RoomTokenExpire(true));
    dispatch(Actions.isPermissionchecked(false));
  };
  async ExpireChexked() {
    this.setState({
      ExpireSnack: true,
    });

    // alert("Che");
    let { dispatch } = this.props;
    let Expire = await localStorage.getItem("DrExpireToken");
    let A = JSON.parse(Expire);
    // console.log("dsvrgnwieugbn", A.Time);
    // console.log("dvsdtuhtnmh", moment(A.Time).subtract(2, 'seconds'));

    // console.log(this.getTime(A.Time));
    // if (this.getTime(A?.Time) == true) {
    this.props.socket.emit("forcedisconnect", null);
    dispatch(DoctorEndServer.ExpireTime(this.props));
    dispatch(Actions.StartCAll(false));
    dispatch(Actions.ExpireSnackBar(true));
    // alert("Your Session has been Expired");
    // this.props.Expiry()
    // }
    // if (moment(Expire.Time).subtract(20, 'seconds')) {
    // alert("dsdvvdvn")
    // }
  }
  getTime(time) {
    let datee = new Date();
    return (
      moment(datee.getTime()).subtract(60, "minutes").format("hh:mm") >=
      moment(time).subtract(0, "minutes").format("hh:mm")
    );
  }

  patientDetail = (Data, i) => {
    this.setState({
      PatientDetais: Data,
      ExtraPAtientData: true,
      index: i,
    });
  };
  // cancelAppointmentHandler = (cancelIndex) => {
  //   console.log("cancelIndex", cancelIndex);
  //   alert("hello", JSON.stringify(cancelIndex));
  //   this.ToggleConfirmationModal(null);
  // };
  // getAllAppointments = () => {
  //   this.props.socket.on("availablepatients", (data) => {
  //     console.log("dsvsdv", data);
  //     if (data?.length !== 0) {
  //       this.setState({
  //         Appoinment: data,
  //         Loading: false,
  //         ExtraPAtientData: false,
  //         index: null,
  //       });
  //     } else {
  //       this.setState({
  //         ExtraPAtientData: false,
  //         index: null,
  //         Appoinment: [],
  //         Loading: false,
  //       });
  //     }
  //   });
  // };
  cancelAppointmentHandler = (cancelIndex) => {
    const roomName = JSON.parse(localStorage.getItem("DrId"));
    console.log("====================================");
    console.log("thisstateAppoinment",this.state.Appoinment[cancelIndex]);
    console.log("===================================="); // this.props.socket.emit("forcedisconnect", null);
    this.props.socket.emit("cancelApt", {
      id: this.state.Appoinment[cancelIndex].data?._id,
      CaRoomname: roomName?.roomName,
      CancelApt: "Yes",
    });
    this.props.socket.emit("DropUser", 

       this.state.Appoinment[cancelIndex].id,
    );
    // this.props.socket.emit("DropUser", {
    //   id: this.state.Appoinment[cancelIndex].id,
    //   data: this.state.Appoinment[cancelIndex].id,
    // });

    // this.getAllAppointments();
    let newArray = [...this.state.Appoinment]; // make a separate copy of the array
    if (cancelIndex !== -1) {
      newArray.splice(cancelIndex, 1);
      this.setState({ Appoinment: newArray, selectedIndex: null });
    }
  };

  ToggleConfirmationModal = (selectedIndex) => {
    this.setState({ selectedIndex });
  };

  render() {
    // const socket = io("http://localhost:3302");
    console.log(
      "doctorData child totalAppointments...",
      this.state.totalAppointments,
      this.props.Details
    );
    // console.log("Snack", snack)
    console.log(this.state);

    let { dispatch } = this.props;
    console.log("this.props.doctor....", this.props.authOpen);
    console.log(
      "this.props.doctor cookies....",
      this.props.cookies.get("doctor"),
      this.props.cookies.get("Details")
    );

    const roomName = JSON.parse(localStorage.getItem("DrId"));
    // setTimeout(() => {
    //   dispatch(Actions.RoomTokenExpire(false))
    // }, 30000);
    // console.log(this.state);
console.log("webcamClose111",this.props.authOpen );
    return this.state.unsupportedBrowser ? (
      <div className="container">
        <Snackbar
          autoHideDuration={3000}
          open={this.props.Patientend_Block_Send_message_to_Dr}
          onClose={() =>
            dispatch(Actions.Patientend_Block_Send_message_to_Dr(false))
          }
        >
          <MuiAlert
            severity="error"
            icon={<img src={CrossVideo} />}
            onClose={() =>
              dispatch(Actions.Patientend_Block_Send_message_to_Dr(false))
            }
            elevation={6}
            variant="filled"
          >
            Call could not start due to camera/mic access permission at
            Patientend
          </MuiAlert>
        </Snackbar>

        <Snackbar
          autoHideDuration={3000}
          open={this.props.Permission}
          onClose={() => dispatch(Actions.RoomTokenExpire(false))}
        >
          <MuiAlert
            severity="error"
            icon={<img src={CrossVideo} />}
            onClose={() => dispatch(Actions.RoomTokenExpire(false))}
            elevation={6}
            variant="filled"
          >
            Allow access to your cam/mic by clicking on blocked cam icon in
            address bar
          </MuiAlert>
        </Snackbar>
        {/* Link */}
        {/* <TelemedDoctorDashboardContainer cookies={this.props.cookies} doctor={this.props.cookies} /> */}
        <div className="inner_container">
          {/* <h1>
            Welcome {this.props.doctor ? this.props.doctor.doctorName : ""}
          </h1> */}
          {/* <div className="Wrap" style={{ justifyContent: "space-between" }}> */}
          <div className="Wrap">
            {/* if you un-comment the history section then you remove this inline style */}
            <div className="Roomlink">
              <h4>Your Telemed Room</h4>
              <h3>Share your URL to invite people in your Room</h3>
              <div className="Link">
                <input
                  onChange={(e) => this.setState({ Token: e.target.value })}
                  value={ encodeURI (`https://${window.location.host}/telemed/${
                    roomName ? roomName?.roomName : ""
                  }`)}
                  // placeholder=
                  disabled
                  type="text"
                />

                <CopyToClipboard
                  text={encodeURI(`https://${window.location.host}/telemed/${
                    roomName ? roomName?.roomName : ""
                  }`)}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <Tooltip
                    title={this.state.copied ? "Copied" : "Copy"}
                    placement="top-start"
                  >
                    {/* <Button>top-start</Button> */}
                    <div style={{ display: "flex" }}>
                      <div className="copylink">
                        <img src={Img} alt="" />
                      </div>
                    </div>
                  </Tooltip>
                </CopyToClipboard>
                {/* {this.state.copied ? <span>Copied.</span> : null} */}
              </div>
            </div>
            <div className="history">
              <p className="history_p1">
                <span className="history_sp1">
                  {this.state.totalAppointmentsLoading ? (
                    <CircularProgress size={30} />
                  ) : this.state.totalAppointments[0]?.Today < 10 ? (
                    "0" + this.state.totalAppointments[0]?.Today
                  ) : (
                    this.state.totalAppointments[0]?.Today
                  )}
                </span>{" "}
                Telemed appointments scheduled for today
              </p>
              <p className="history_p2">
                <span className="history_sp1">
                  {this.state.totalAppointmentsLoading ? (
                    <CircularProgress size={30} />
                  ) : this.state.totalAppointments[0]?.Future < 10 ? (
                    "0" + this.state.totalAppointments[0]?.Future
                  ) : (
                    this.state.totalAppointments[0]?.Future
                  )}
                </span>{" "}
                Telemed appointments scheduled for future
              </p>
            </div>
            <DrPermission
              BrwsernotSupporter={this.BrwsernotSupporter}
              Safricancel={this.Safricancel}
              webcamClose={this.props.webcamClose}
            />
          </div>
          {/* Link end */}
          <PatientInformation
            AppoinmentData={this.state.Appoinment}
            startcall={this.startcall}
            cancelAppointmentHandler={this.cancelAppointmentHandler}
            ToggleConfirmationModal={this.ToggleConfirmationModal}
            selectedIndex={this.state.selectedIndex}
            disconnectSocket={this.props.socket}
            onCallEnd={this.props.onCallEnd}
            cookies={this.props.cookies}
            doctorName={this.props.doctorName}
          />
        </div>
      </div>
    ) : (
      <BrowsweUnsupported />
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("====================================");
  console.log("sdcdsv", state.TelemedOpenmodal);
  // console.log("====================================");
  return {
    ...state,

    // Open: state.TelemedOpenmodal.Open,
    doctor: state.TelemedicineDocotorAuthReducer.doctor,
    Details: state.TelemedicineDocotorAuthReducer.Details,
    Patientend_Block_Send_message_to_Dr:
      state.TelemedOpenmodal.Patientend_Block_Send_message_to_Dr,
    Permission: state.TelemedOpenmodal.RoomTokenExpire,
    ExpireSnackBar: state.TelemedOpenmodal.ExpireSnackBar,
    StartCall: state.TelemedOpenmodal.StartCall,
    // ExpireSnackBar
    authOpen: state.TelemedicineDocotorAuthReducer.authOpen,

    connectedPatient: state.TelemedOpenmodal.connectedPatient,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     Expiry: () => {
//       DoctorEndServer.logoutDoctor()
//     },
//     StartCall: (data) => {
//       Actions.StartCAll(true, data)
//     }
//   }
// }
export default connect(mapStateToProps)(Dashboard);

// {/* <div className="alignn">
//             <div className="waitinge">
//               <h2>Your waiting room</h2>
//               <div>
//                 {this.state.Appoinment.length !== 0 ? (
//                   <React.Fragment>
//                     {this.state.Appoinment &&
//                       this.state.Appoinment.map((res, i) => {
//                         console.log("sdvsdvsdv", res);
//                         var now = moment(new Date());
//                         var duration = moment.duration(now.diff(res.time));
//                         var hours = duration.minutes();
//                         console.log("dcdsvdsvvd", hours);

//                         return (
//                           <React.Fragment>
//                             <div className="waitpatient" key={i}>
//                               <span>{i + 1}.</span>
//                               <p>
//                                 {/* fas */}
//                                 {res.data?.patientFirstName}
//                               </p>
//                               <div className="time">
//                                 <img src={Img3} alt="" />
//                                 <span>
//                                   {moment
//                                     .utc()
//                                     .hours(hours)
//                                     .minutes(hours)
//                                     .format("m")}
//                                   min
//                                 </span>
//                                 {/* <span> {moment().startOf('hour').fromNow()} </span> */}
//                                 {/* <span> {hours} </span> */}
//                               </div>
//                               <button
//                                 onClick={() => this.startcall(res?.id)}
//                                 className="btn"
//                               >
//                                 Start Call
//                               </button>
//                               {/* {this.state.PatientDetais.patientFirstName !== res.data.patientFirstName ? */}

//                               {i == this.state.index ? (
//                                 <button
//                                   onClick={() =>
//                                     this.setState({
//                                       ExtraPAtientData: false,
//                                       index: null,
//                                     })
//                                   }
//                                   className="btn-hidedetails"
//                                 >
//                                   Hide Details
//                                 </button>
//                               ) : (
//                                 <button
//                                   onClick={() =>
//                                     this.patientDetail(res?.data, i)
//                                   }
//                                   className="btn-details"
//                                 >
//                                   Details
//                                 </button>
//                               )}
//                             </div>
//                           </React.Fragment>
//                         );
//                       })}
//                   </React.Fragment>
//                 ) : this.state.Appoinment.length !== 0 ? (
//                   <p
//                     style={{ fontSize: 12, paddingTop: 20, paddingBottom: 30 }}
//                   >
//                     Seems like no one has joined yet
//                   </p>
//                 ) : this.state.Loading == true ? (
//                   <Loader />
//                 ) : (
//                   ""
//                 )}
//                 {this.state.Loading == false &&
//                 this.state.Appoinment.length == 0 ? (
//                   <p
//                     style={{ fontSize: 12, paddingTop: 20, paddingBottom: 30 }}
//                   >
//                     Seems like no one has joined yet
//                   </p>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//             {this.state.ExtraPAtientData == true ? (
//               <div className="DetailsModal">
//                 <div className="Close-Btn">
//                   <button
//                     onClick={() =>
//                       this.setState({ ExtraPAtientData: false, index: null })
//                     }
//                   >
//                     X
//                   </button>
//                 </div>
//                 <div className="content">
//                   <h3>Appointment Information</h3>
//                   <div className="info">
//                     <span className="Keys">Appointment Date</span>
//                     <span className="valuess">
//                       {moment(this.state.PatientDetais?.appointmentDate).format(
//                         "MMMM Do YYYY"
//                       )}
//                     </span>
//                   </div>
//                   <div className="info">
//                     <span className="Keys"> Appointment Time</span>
//                     <span className="valuess">
//                       {this.state.PatientDetais?.appointmentTime}
//                     </span>
//                   </div>
//                   {this.state.PatientDetais?.PetDetails.length > 0 ? (
//                     <div className="info">
//                       <span className="Keys">pet number</span>
//                       <span className="valuess">
//                         {this.state.PatientDetais?.PetDetails.length}
//                       </span>
//                     </div>
//                   ) : (
//                     <div className="info">
//                       <span className="Keys">Reason</span>
//                       <span className="valuess">
//                         {this.state.PatientDetais?.reasonForVisit}
//                       </span>
//                     </div>
//                   )}
//                 </div>
//                 <div className="content">
//                   <h3>
//                     {this.state.PatientDetais?.PetDetails.length > 0
//                       ? "Pet owner  Information"
//                       : "Personal Information"}
//                   </h3>
//                   <div className="info">
//                     <span className="Keys">Name</span>
//                     <span className="valuess">
//                       {this.state.PatientDetais?.patientFirstName}
//                     </span>
//                   </div>
//                   {this.state.PatientDetais?.PetDetails.length > 0 ? (
//                     ""
//                   ) : (
//                     <div className="info">
//                       <span className="Keys">Date of Birth</span>
//                       <span className="valuess">
//                         {moment(this.state.PatientDetais?.patientDob).format(
//                           "MMMM Do YYYY"
//                         )}
//                       </span>
//                     </div>
//                   )}
//                   <div className="info">
//                     <span className="Keys">Email</span>
//                     <span className="valuess">
//                       {this.state.PatientDetais?.patientEmail}
//                     </span>
//                   </div>
//                   <div className="info">
//                     <span className="Keys">Phone</span>
//                     <span className="valuess">
//                       {this.state.PatientDetais?.patientContact}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               ""
//             )}
//           </div> */}
