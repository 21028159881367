const commentSubmission_initialState = {
  Open: true,
  AuthDataRoom: {},
  DrAuthTOkenVerify: {},
  StartCall: false,
  patientDataEmailAfterverify: {},
  DrDataFromPatinet: {},
  StartCAllForPatientend: false,
  DrMuteToPatient: false,
  PatientMuteToDr: false,
  ID: "",
  Appoinments: {},
  RoomTokenExpire: false,
  isPermissionchecked: false,
  Patientend_Block_Send_message_to_Dr: false,
  ExpireSnackBar: false,
  SelectData: {},
};

const Reducer = function (state = commentSubmission_initialState, action) {
  console.log(action.type);
  switch (action.type) {
    case "OpenModal":
      return {
        ...state,
        Open: action.payload,
      };
    case "AuthData":
      return {
        ...state,
        AuthDataRoom: action.payload,
      };
    case "DrAuthTOkenVerify":
      return {
        ...state,
        DrAuthTOkenVerify: action.payload,
      };
    case "StartCall":
      return {
        ...state,
        StartCall: action.payload,
        connectedPatient: action.Data,
        SelectData: action.SelectedData,
      };
    case "patientDataEmailAfterverify":
      return {
        ...state,
        patientDataEmailAfterverify: action.payload,
      };
    case "DrDataFromPatinet":
      return {
        ...state,
        DrDataFromPatinet: action.payload,
      };
    case "StartCAllForPatientend":
      return {
        ...state,
        StartCAllForPatientend: action.payload,
      };
    case "DrMuteToPatient":
      return {
        ...state,
        DrMuteToPatient: action.payload,
      };
    case "PatientMuteToDr":
      return {
        ...state,
        PatientMuteToDr: true,
      };
    case "DrID":
      return {
        ...state,
        ID: action.payload,
      };
    case "RoomTokenExpire":
      return {
        ...state,
        RoomTokenExpire: action.payload,
      };
    case "isPermissionchecked":
      return {
        ...state,
        isPermissionchecked: action.payload,
      };
    case "Patientend_Block_Send_message_to_Dr":
      return {
        ...state,
        Patientend_Block_Send_message_to_Dr: action.payload,
      };
    case "ExpireSnackBar":
      return {
        ...state,
        ExpireSnackBar: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default Reducer;
