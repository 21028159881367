import React, { Component } from 'react'
import "../../../src/css/components/SingleLoader.css"

class SingleLoader extends Component {
    render() {
        const {title="Loading ...", }= this.props
        return (
          
          <div className="tl-container">
            <span className="tl" style={{borderTop:`3px solid ${this.props.color}`}} ></span>
             <p className="tl-p1">{title}</p>
          </div>
        );
    }
}

export default SingleLoader

