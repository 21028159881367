import React, { Component, Fragment } from "react";
import io from "socket.io-client";
import { ROOT_URL } from "../../../../constants/config";
import { telemedicineDoctor_Auth_Status } from "./TelemedDoctorAuth_constants";
import LoadingScreen from "../../../utils/TransparentLoader";
import VideoCall from "../../Room";
import MainDashboard from "../MainDahsboardScreen";
import store from "../../../../store/index";
import * as Actions from "../../../../servers/Telemed";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../../utils/SingleLoader";
//import SnackBar from "../utils/SnackBar"
import TelemedDocotorAuth from "./TelemedDocotorAuth";
// import TelemedHourModal from "./TelemedHourModal";
import Axios from "axios";

class TelemedicineDoctorAuth_View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Snackbar: false,
      vertical: "top",
      horizontal: "center",
      Msg: true,
      PatientPrmission: false,
      Open: null,
      Settelemed: false,
      ring: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
    };
    // props.resetReducerState()
  }
  componentDidMount() {
    // this.getTelemedHOurslimit();
    // document.querySelector('#my-button').addEventListener('click', function(event) {
    // Permissions must be requested from inside a user gesture, like a button's
    // click handler.
    // });
  }
  componentDidUpdate(prevprops) {
    if (prevprops.Details !== this.props.Details) {
      this.setState({
        Open: this.props?.Details?.enableDoctors,
      });
    }
  }

  onCallEnd = () => {
    this.props.socket.emit("DropUser", this.props.connectedPatient);
    store.dispatch(Actions.StartCAll(false));
  };
  onCallDrop = () => {
    store.dispatch(Actions.StartCAll(false));

    this.setState({
      Snackbar: true,
    });
// this.state.Snackbar= true
  };
  Muteed = () => {
    this.props.socket.emit("ShowstaticImg", {
      CallMuteRoomname: this.props.doctor?.roomName,
      Mute: false,
    });
    // alert("Dr")
  };
  RoomTokenExpire = () => {
    this.props.logout();
  };
  Permission = (res) => {
    store.dispatch(Actions.StartCAll(false));
    // alert("call could not start due to camera/microphone access permission denied  at patient end ")
    // this.props.socket.emit("DropUser", this.props.connectedPatient)
    // if (this.props.Permission == true) {
    // store.dispatch(Actions.RoomTokenExpire(true))
    // }
  };
  Ringing = () => {
    // alert("Ringing")
    this.setState({
      PatientPrmission: true,
    });
  };
  getTelemedHOurslimit = () => {
    let body = {
      doctorId: this.props.cookies.get("doctor")?._id,
    };
    Axios.post(`${ROOT_URL}/AuthTokenBasedOnDoctorId`, body).then((res) => {
      console.log("sdfdsfdsfdsfd", res.data);
      this.setState({
        Open: res?.data?.enableDoctors,
      });
    });
  };
  getScreen(status) {
    console.log("telemedicineDoctor_Auth_Status", status);
    // let ringing = new Audio(this.state.ring);
    if (
      status ==
        telemedicineDoctor_Auth_Status.TelemedicineDoctor_Auth.SUCCESS ||
        localStorage.getItem("DrId")!= undefined
    ) {
      // const doctor =
      //   this.props.cookies.get("doctor") != undefined
      //     ? this.props.cookies.get("doctor")
      //     : this.props.doctor;
      // console.log("********", doctor, this.props.cookies.get("doctor"));
      const doctors=localStorage.getItem("DrId")
      if (this.props.StartCall != true)
        console.log("egefiejf7e6f", this.props.socket);
      this.props.socket.connect(ROOT_URL);
      this.props.socket.emit("DrUser",JSON.parse(doctors) );

      if (this.props.cookies.get("doctor") == undefined)
        this.props.cookies.set("doctor", this.props.doctor, {
          path: "/doctor",
        });
      this.props.cookies.set("Details", this.props.Details, {
        path: "/doctor",
      });
      console.log("StartCallStartCall",this.props.StartCall,this.props.isPermissionchecked,doctors);
      return (
        <Fragment>
          {this.props.StartCall === true ? (
            <VideoCall
              cookies={this.props.cookies}
              dispatch={store.dispatch}
              roomName={JSON.parse(doctors).roomName}
              token={this.props.StartCall ? JSON.parse(doctors).roomToken : null}
              socket={this.props.socket}
              onCallEnd={this.onCallEnd}
              onCallDrop={this.onCallDrop}
              Permission={this.Permission}
              Ringing={this.Ringing}
              Msg={this.state.Msg}
              RoomTokenExpire={this.RoomTokenExpire}
              Muteed={this.Muteed}
              // socket={this.props.socket}
            />
          ) : this.props.isPermissionchecked == true ? (
            <div
              className="waitingDrQue"
              style={{
                height: "100vh",
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100vw",
                zIndex: 1202,
              }}
            >
              {/* {ringing.play()} */}
              <Loader title="Finalizing connection, please stand by" />
            </div>
          ) : (
            <MainDashboard
              socket={this.props.socket}
              doctor={JSON.parse(doctors)}
              cookies={this.props.cookies}
              onCallEnd={this.onCallEnd}
              doctorName={this.props.doctorName}
              onCallDrop={this.onCallDrop}
              webcamClose={status=="TELEMEDICINEDOCTOR_AUTH_NEW"?true:false}
            />
          )}
        </Fragment>
      );
    } else {
      return <Fragment />;
    }
  }
  logout = () => {
    this.props.logout();
    this.setState({
      Open: false,
    });
  };
  render() {
    let authOpen = this.props.authOpen;
    let dat=localStorage.getItem("DrId")
    console.log("Dsvkmsdvk",JSON.parse(dat));
    console.log("Details", this.props.Details, this.state);

    return (
      <Fragment>
        {/* <Snackbar
          autoHideDuration={2000}
          open={this.state.PatientPrmission}
          onClose={() => this.setState({ Snackbar: false })}
        >

          <MuiAlert severity="warning" onClose={() => this.setState({ Snackbar: false })} elevation={6} variant="filled">
            Call Drop by pavdslvmdskvmdsvtient
      </MuiAlert>

        </Snackbar> */}
        <Snackbar
          autoHideDuration={2000}
          open={this.state.Snackbar}
          onClose={() => this.setState({ Snackbar: false })}
        >
          <MuiAlert
            severity="warning"
            onClose={() => this.setState({ Snackbar: false })}
            elevation={6}
            variant="filled"
          >
            Call dropped by patient
          </MuiAlert>
        </Snackbar>
        {/* <Snackbar ContentProps={{
          'aria-describedby': 'message-id',
        }}
          open={this.state.Snackbar} autoHideDuration={1000}>
          <MuiAlert severity="warning" onClose={() => this.setState({ Snackbar: false })} elevation={6} variant="filled">
            Call Drop by patient
        </MuiAlert>
          
        </Snackbar> */}
        {localStorage.getItem("DrId") != undefined
          ? (authOpen = false)
          : (authOpen = this.props.authOpen)}
        <TelemedDocotorAuth
          error={this.props.error}
          authOpen={authOpen}
          cookies={this.props.cookies}
          authenticateDoctor={this.props.authenticateDoctor}
        />
        {/* {this.state.Open == true ||
        this.state.Open === null ||
        this.state.Open == undefined ? (
          ""
        ) : (
          <TelemedHourModal logout={this.logout} Toggle={!this.state.Open} />
        )} */}

        {this.getScreen(this.props.telemedicineDoctor_Auth_Status)}
      </Fragment>
    );
  }
}

export default TelemedicineDoctorAuth_View;
