// export const ROOT_URL = "http://192.168.1.8:3302";
//export const ROOT_URL = "http://localhost:3302";
export const ROOT_URL = "https://terraformapi.zebra.doctor";
//export const ROOT_URL = "http://localhost:3302";
//export const WEB_URL = "http://localhost:3005";
export const WEB_URL = "https://televisit.zebra.doctor/";
export const PracticeAval = {
  PractiveAvalSlots: 15,
};

// export const ROOT_URL = "http://192.168.0.109:3302";
export const PracticeIconName = "";
