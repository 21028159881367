import React, { useEffect, useState } from "react";
import CrossVideo from "../../../images/no-video-(4).png";
import CrossVideored from "../../../images/no-video-(5).png";
import { Button } from "@material-ui/core";
import { Mic } from "@material-ui/icons";
import Webcamm from "react-webcam";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TestCall from "./TestCall";
export default function PatientPermession(props) {
  const [Permissions, setPermissions] = useState(null);
  const [Message, setMessage] = useState(false);
  const [Snack, setSnack] = useState(false);
  const [Browser, setBrowser] = useState("");
  const [OpenMicTest, setOpenMicTest] = useState(false);
  const GetPermission = () => {
    // console.log("bgt", navigator, JSON.st navigator.);
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        console.log("here", stream);
        setPermissions(true);
      })
      .catch((error) => {
        if (
          error.message == "getUserMedia is not implemented in this browser"
        ) {
          props.BrwsernotSupporter(false);
        } else if (
          error.message ==
          "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
        ) {
          setPermissions(false);
          setMessage(true);
          setSnack(true);
          // props.Safricancel();
        }
      });
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(
        // navigator.permissions.query({ name: "camera" }).then(
        (permissionStatus) => {
          console.log("geolocation permission state is ", permissionStatus);
          if (permissionStatus.active == true) {
            setPermissions(true);
          } else if (permissionStatus.active == false) {
            setMessage(true);
            setSnack(true);
          } else {
            setPermissions(false);
          }
          permissionStatus.onchange = (e) => {
            console.log(
              "geolocation permission state has changed to ",
              e.currentTarget.active
            );
            if (e.currentTarget.active == true) {
              setPermissions(true);
            } else {
              setPermissions(false);
              setMessage(true);
              setSnack(true);
            }
          };
        }
      )
      .catch((error) => {
        if (
          error.message == "getUserMedia is not implemented in this browser"
        ) {
          props.BrwsernotSupporter(false);
        } else if (
          error.message ==
          "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
        ) {
          setPermissions(false);
          setMessage(true);
          setSnack(true);
          // props.Safricancel();
        } else {
          if (navigator.permissions != undefined) {
            navigator.permissions
              .query({ name: "video" })
              .then((permissionStatus) => {
                if (permissionStatus.state == "granted") {
                  setPermissions(true);
                } else if (permissionStatus.state == "denied") {
                  setMessage(true);
                  setSnack(true);
                } else {
                  setPermissions(false);
                }
                permissionStatus.onchange = (e) => {
                  console.log(
                    "geolocation permission state has changed to ",
                    e.currentTarget.active
                  );
                  if (e.currentTarget.active == true) {
                    setPermissions(true);
                  } else {
                    setPermissions(false);
                    setMessage(true);
                    setSnack(true);
                  }
                };
              })
              .catch((error) => {
                setMessage(true);
                setSnack(true);
                console.log("*$", error);
              });
          }
        }
      });
    // }) ;

    if (navigator.userAgent.indexOf("Chrome") != -1) {
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      setBrowser("safari");
      // alert("Safari");
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    }
    // else if (
    //   navigator.userAgent.indexOf("MSIE") != -1 ||
    //   !!document.documentMode == true
    // ) {
    //   //IF IE > 10
    //   alert("IE");
    // }
    else {
      // alert("unknown");
    }
  }, []);
  const OpenTestCallMicModal = (v) => {
    setOpenMicTest(v);
  };
  return (
    <React.Fragment>
      {Browser == "safari" ? (
        <Snackbar
          autoHideDuration={3000}
          open={Snack}
          onClose={() => setSnack(false)}
        >
          <MuiAlert
            severity="error"
            icon={<img style={{ height: 30, width: 30 }} src={CrossVideo} />}
            onClose={() => setSnack(false)}
            elevation={6}
            variant="filled"
          >
            Refresh the page to allow cam/mic access
          </MuiAlert>
        </Snackbar>
      ) : (
        <Snackbar
          autoHideDuration={3000}
          open={Snack}
          onClose={() => setSnack(false)}
        >
          <MuiAlert
            severity="error"
            icon={<img style={{ height: 30, width: 30 }} src={CrossVideo} />}
            onClose={() => setSnack(false)}
            elevation={6}
            variant="filled"
          >
            Allow access to your cam/mic by clicking on blocked cam icon in
            address bar
          </MuiAlert>
        </Snackbar>
      )}
      <div className="CallPermission">
        {Message == true ? (
          <React.Fragment>
            <img className="cp_image_red" src={CrossVideored} alt="" />
            {Browser == "safari" ? (
              <p className="cp_p1_red">
                Refresh the page to allow cam/mic access
              </p>
            ) : (
              <p className="cp_p1_red">
                Allow access to your cam/mic by clicking on blocked cam icon in
                address bar
              </p>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {Permissions == true ? (
              <div className="cp_webcam_parent">
                <Webcamm
                  mirrored={true}
                  className="cp_webcam"
                  audio={true}

                  // ref={webcamRef}
                  // screenshotFormat="image/jpeg"
                  // videoConstraints={{ facingMode: { exact: "left" } }}
                />
                {/* <Button
                  onClick={() => {
                    OpenTestCallMicModal(true);
                  }}
                  endIcon={<Mic />}
                  className="TedstVidecCallbtn"
                >
                  Test Mic
                </Button> */}
              </div>
            ) : (
              <React.Fragment>
                <img className="cp_image" src={CrossVideo} alt="" />
                <p className="cp_p1">
                  Your webcam <br />
                  isn't enabled yet.
                </p>
                <button
                  onClick={() => GetPermission()}
                  className="get-permission-btn"
                >
                  Enable Camera
                </button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
      {/* <TestCall
        OpenTestCallMicModal={OpenTestCallMicModal}
        OpenMicTest={OpenMicTest}
      /> */}
    </React.Fragment>
  );
}
