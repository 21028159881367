import React, { Component } from "react";
import "../../../css/components/_PatinetEndMenuBar.scss";
export class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="navigation2">
        <div className="mb-div1">
          <i
            className="material-icons mb-icon mb-icon1"
            title="Inquiries"
            style={{
              //   borderLeft: this.props.border1,
              //   backgroundColor: this.props.color1
            }}
          // onClick={() => {
          //   this.props.onHomeClick();
          // }}
          >
            dashboard
          </i>
          {/* <i
            className="material-icons mb-icon"
            title="Appointments"
            style={{
              //   borderLeft: this.props.border2,
              //   backgroundColor: this.props.color2
            }}
          // onClick={() => {
          //   this.props.onAppointmentsClick();
          // }}
          >
            home
          </i> */}
        </div>
      </nav>
    );
  }
}

export default MenuBar;
