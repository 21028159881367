import React, { Component } from "react";
import "./_TableHeader.scss";
class TableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  SeacrDoctor = (e) => {
    this.props.SearchDoctor(e.target.value);
  };
  render() {
    console.log("====================================");
    console.log(this.props);
    console.log("====================================");
    return (
      <div className="telemed-th-container">
        {/* <p className="telemed-th-p1">{this.props.practice.practiceID.practiceName}</p> */}
        <p className="telemed-th-p1">Telemedicine waiting room</p>

        {/* <div className="telemed-th-div1">
          <label htmlFor="telemed-th-inp-search" className="telemed-th-label1">
            <input
              onChange={this.SeacrDoctor}
              type="text"
              name="search"
              id="telemed-th-input-search"
              placeholder="Name, email or phone"
              className="telemed-th-inp1"
            />
            <i className="material-icons telemed-th-icon1">search</i>
          </label>
        </div> */}
      </div>
    );
  }
}

export default TableHeader;
