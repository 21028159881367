import { connect } from "react-redux";
import TelemedDoctorAuth_View from "./TelemedDoctorAuth_View";
import { DoctorEndServer } from "../server";
import { TelemedicineDoctor_Auth_Actions } from "./TelemedDoctorAuth_constants";
const mapStateToProps = (state) => {
  console.log("edvmksv", state.TelemedicineDocotorAuthReducer.error,state.TelemedOpenmodal.StartCall);
  return {
    Permission: state.TelemedOpenmodal.RoomTokenExpire,
    telemedicineDoctor_Auth_Status:
      state.TelemedicineDocotorAuthReducer.telemedicineDoctor_Auth_Status,
    authOpen: state.TelemedicineDocotorAuthReducer.authOpen,
    doctor: state.TelemedicineDocotorAuthReducer.doctor,
    Details: state.TelemedicineDocotorAuthReducer.Details,
    error: state.TelemedicineDocotorAuthReducer.error,
    StartCall: state.TelemedOpenmodal.StartCall,
    isPermissionchecked: state.TelemedOpenmodal.isPermissionchecked,
    connectedPatient: state.TelemedOpenmodal.connectedPatient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateDoctor: (data, Email) => {
      DoctorEndServer.authenticateDoctor(data, Email);
    },
  };
};
const TelemedDoctorAuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelemedDoctorAuth_View);

export default TelemedDoctorAuthContainer;
