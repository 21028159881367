import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Success from "../../../../images/telemedConfirmation.png";
import "./Style.scss";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  console.log("props.onCallEnd....", props.onCallEnd);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {/* <div className={classes.paper}> */}
          <div className="confirmation_SuccessModal">
            <img src={Success} alt="" />
            <h1>Sure you want to cancel appointment?</h1>
            {/* <p>
                Thank you for your request, We will contact you to schedule a
                Demo shortly.
              </p> */}
            <div className="confirmation_btnContainer">
              <Button
                onClick={() => props.ToggleConfirmationModal(null)}
                className="SuccessModal-BTN"
              >
                No
              </Button>
              <Button
                // onClick={() =>
                //   props.disconnectSocket.emit("forcedisconnect", null)
                // }
                // onClick={props.onCallEnd}
                onClick={() =>
                  props.cancelAppointmentHandler(props.selectedIndex)
                }
                className="SuccessModal-BTN"
              >
                Yes
              </Button>
            </div>
            {/* </div> */}
            {/* <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">
              react-transition-group animates me.
            </p> */}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
