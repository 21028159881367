import {
  telemedicineDoctor_Auth_Status,
  TelemedicineDoctor_Auth_Actions,
} from "./TelemedDoctorAuth_constants";
const appointments_initialStoreState = {
  telemedicineDoctor_Auth_Status:
    telemedicineDoctor_Auth_Status.TelemedicineDoctor_Auth.NEW,
  authOpen: true,
  doctor: {},
  Details: {},
};

const TelemedicineDocotorAuthReducer = function (
  state = appointments_initialStoreState,
  action
) {
  console.log(action.type);
  switch (action.type) {
    case TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.GET:
      return appointments_initialStoreState;
    case TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.LOADING:
      return {
        ...state,
        telemedicineDoctor_Auth_Status:
          telemedicineDoctor_Auth_Status.TelemedicineDoctor_Auth.LOADING,
        authOpen: true,
        error: "",
      };
    case TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.SUCCESS:
      return {
        ...state,
        telemedicineDoctor_Auth_Status:
          telemedicineDoctor_Auth_Status.TelemedicineDoctor_Auth.SUCCESS,
        doctor: action.payload.doctor,
        authOpen: false,
        Details: action.payload,
        error: "",
      };
    case TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.FAILURE:
      return {
        ...state,
        telemedicineDoctor_Auth_Status:
          telemedicineDoctor_Auth_Status.TelemedicineDoctor_Auth.FAILURE,
        error: action.payload,
        authOpen: true,
      };

    default:
      console.log("default is firing");

      return { ...state };
  }
};
export default TelemedicineDocotorAuthReducer;
