import React, { Component } from "react";
import * as Actions from "../../../servers/Telemed";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-responsive-modal/styles.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../../../css/components/_Success.scss";
import "../../../css/components/_TelemedDoctoeAuth.scss";
import HelpCenter from "../TelemedDoctorEnd/HelpCenter";
import Menubar from "./Menubar";
import MaskedInput from "react-text-mask";
import {
  makeStyles,
  useTheme,
  withStyles,
  withTheme,
} from "@material-ui/core/styles";
import {
  TextField,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
  ListItem,
  Snackbar,
} from "@material-ui/core";
// import {
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   MenuIcon,
//   Dashboard,
//   Notifications,
//   Help,
// } from "@material-ui/icons";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";

import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";
import Notifications from "@material-ui/icons/Notifications";
import Axios from "axios";
import Header from "./Header";
import moment from "moment";
import { withRouter } from "react-router-dom";
import MainPatiendScreePatientDashboard from "./MainScreen";
import VideoCall from "../Room";
import { ROOT_URL } from "../../../constants/config";
import MuiAlert from "@material-ui/lab/Alert";
import ButtonLoader from "../../utils/ButtonLoader";
import io from "socket.io-client";
import CrossVideo from "../../../images/no-video(2).png";
import camera from "../../../images/tda_camera.png";
import logoImage from "../../../images/lpage3Logo.png";
import logoWhite from "../../../images/lpage3LogoWhite.png";
import profile from "../../../images/personProfileImage.png";
import profileBlack from "../../../images/personProfileImageBlack.png";
import "../TelemedDoctorEndNewDesign/TelemedDoctorContainer/TelemedDoctorContainer.scss";
import DocumentMeta from "react-document-meta";
const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
    ["@media (max-width:1080px)"]: {
      display: "block",
    },
  },
  newRoot: {
    display: "block",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ["@media (max-width:1080px)"]: {
      marginLeft: 0,
      width: `100%`,
    },
  },
  menuButton: {
    marginRight: 36,
    ["@media (max-width:1080px)"]: {
      display: "none",
    },
  },
  menuButtonNew: {
    display: "none",
    marginLeft: 10,
    marginRight: -10,
    ["@media (max-width:1080px)"]: {
      display: "flex",
    },
    ["@media (max-width:360px)"]: {
      marginLeft: 2,
    },
  },
  hide: {
    display: "none",
  },
  hideNew: {
    display: "none",
    ["@media (max-width:1080px)"]: {
      display: "flex",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 10,
    "&:hover": {
      width: drawerWidth,
    },
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9) + 1,
    // },
    ["@media (max-width:1080px)"]: {
      width: 0,
      "&:hover": {
        width: 0,
      },
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    ["@media (max-width:450px)"]: {
      padding: theme.spacing(0),
    },
  },
  content1: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  iconStyle: {
    color: "#000",
  },
  textStyle: {
    // fontFamily: "Open Sans",
    // fontSize: "18px",
    // fontWeight: "700",
    // color: "blue",
  },
});

class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      emailFlag: false,
      emailErrorMsg: "Enter valid email",
      Data: {},
      Message: "",
      EmailVerify: true,
      DrAppoinmentData: {},
      doctor: null,
      Sessionexpired: "",
      particpent: "",
      StartCall: false,
      doctorAvailabe: false,
      waitingList: "0",
      myTurn: "-",
      snackbar: false,
      DoctorData: {},
      appointmentTimeError: "",
      ButtonLoader: false,
      ExpiredSnack: false,
      open: false,
      hover: false,
      PatientData: "",
      Tab: "Dashboard",
      profilePopUp: false,
      phoneNumber: false,
      phoneNumbervalue: "",
      Check: false,
      PatientLoginError: ""
    };
    const { dispatch } = props;
    this.boundActionCreators = bindActionCreators(Actions, dispatch);
  }
  componentDidMount() {
    console.log("====================================");
    console.log("fdvdfvdfv", window.location.href, this.props.socket);
    console.log("====================================");
    this.props.socket.emit("forcedisconnect", null);

    let socket = this.props.socket;
    if (socket.disconnected) {
      socket.connect();
    }
    socket.on("PatientEndEndCalls", (data) => {
      console.log("PatientEndEndCalls",data);
      if (data.Id == this.state.PatientData._id) {
        let { dispatch } = this.props;
        this.props.socket.emit("forcedisconnect", null);
        dispatch(Actions.OpenModal(true));
        dispatch(Actions.StartCAll(false));
        this.setState({
          appointmentTimeError: "",
          profilePopUp: false,
          CancelApt: true,
        });
      }
    });
  }
  onCloseModal = () => {
    let { dispatch } = this.props;
    this.props.socket.emit("forcedisconnect", null);
    dispatch(Actions.OpenModal(true));
    dispatch(Actions.StartCAll(false));
    this.setState({
      appointmentTimeError: "",
      profilePopUp: false,
    });
  };
  logoutHandler = (e) => {
    e.stopPropagation();
    let { dispatch } = this.props;
    this.props.socket.emit("forcedisconnect", null);
    dispatch(Actions.OpenModal(true));
    dispatch(Actions.StartCAll(false));
    this.setState({
      appointmentTimeError: "",
      profilePopUp: false,
    });
  };

  Tabchange = (Tab) => {
    this.setState({ Tab });
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerOpenNew = () => {
    this.setState({ open: !this.state.open });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handlerMouseOver = () => {
    this.setState({ hover: true });
  };

  handlerMouseOut = () => {
    this.setState({ hover: false });
  };

  Cantclose = () => {
    // alert("Authenticate the Email");
  };
  Submit = (e) => {
    // e.preventDefault();
    let { dispatch } = this.props;
    this.setState({
      ButtonLoader: true,
    });
    ///EmailVerify

    const config = {
      // patientContact: this.state.emailFlag ? this.state.phoneNumbervalue : "",
      // Email: !this.state.emailFlag ? this.state.Email : "",
      Email: this.state.Email,
      Roomname: this.props.match.params.Roomname,
    };
    Axios.post(`${ROOT_URL}/PatientEmialVerify`, config, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      let date = Date.now();
      console.log("dsvjbdshlocalhost:Submit3000/telemed/rfeiHvb", res.data);
      // if (moment(res.data.TokenData.appointmentDate).format() <
      //   moment(date).format() || moment(date).format('h:mm A') < res.data.TokenData.appointmentTime) {
      //   alert("No appointment today 1")
      // }
      // else if (moment(res.data.TokenData.appointmentDate).format() >
      //   moment(date).format() || moment(date).format('h:mm A') > res.data.TokenData.appointmentTime) {
      //   alert("No appointment today 2")
      // }

      this.setState({ PatientData: res.data.TokenData });

      if (res.data.TokenData) {
        this.setState({
          // Email: "",
          appointmentTimeError: "",
          ButtonLoader: false,
        });
        console.log(
          moment(res.data.TokenData.appointmentDate).format("L"),
          moment(date).format("L")
        );
        // if (
        //   moment(res.data.TokenData.appointmentDate).format("L") ===
        //   moment(date).format("L")
        // ) {
        this.setState({ Data: res.data, Message: "", Email: "" });
        dispatch(Actions.patientDataEmailAfterverify(res.data));
        dispatch(Actions.OpenModal(false));
        let now = new Date();
        localStorage.clear();
        localStorage.setItem(
          "PatientExpire",
          JSON.stringify({ data: res.data.doctor, Time: now.getTime() })
        );
        this.setState({
          DoctorData: res.data.doctor,
        });
        var socket = this.props.socket;
        if (this.props.match.params.Roomname) {
          res.data.TokenData.roomName = this.props.match.params.Roomname;
          if (socket.disconnected) {
            socket.connect();
          }
        }

        socket.on("StartCall", (data) => {
          console.log("====================================");
          console.log("datadsvdsv", data);
          console.log("====================================");
          // this.setState({
          //   StartCall: data.PatientStart,
          // });

          navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then(
              // navigator.permissions.query({ name: "camera" }).then(
              (permissionStatus) => {
                console.log("====================================");
                console.log("asdsadsadsa",permissionStatus);
                console.log("====================================");
                if (permissionStatus.active) {
                  dispatch(Actions.StartCAll(true));
                  this.props.socket.emit("patientCallPermissionIssue", {
                    roomName: this.props.match.params.Roomname,
                    patientPErmissn: false,
                  });
                } else {
                  this.props.socket.emit("patientCallPermissionIssue", {
                    roomName: this.props.match.params.Roomname,
                    patientPErmissn: true,
                  });
                  this.Permission();
                  dispatch(Actions.RoomTokenExpire(true));
                }
              }
            )
            .catch((error) => {
              console.log("====================================");
              console.log(error.name);
              console.log("====================================");
              if (error.name == "NotAllowedError") {
                this.props.socket.emit("patientCallPermissionIssue", {
                  roomName: this.props.match.params.Roomname,
                  patientPErmissn: true,
                });
                this.Permission();
                dispatch(Actions.RoomTokenExpire(true));
              } else if (navigator.permissions != undefined) {
                navigator.permissions
                  .query({ name: "camera" })
                  .then((permissionStatus) => {
                    console.log(
                      "geolocation permission state is ",
                      permissionStatus.state
                    );
                    if (permissionStatus.state == "granted") {
                      dispatch(Actions.StartCAll(true));
                      this.props.socket.emit("patientCallPermissionIssue", {
                        roomName: this.props.match.params.Roomname,
                        patientPErmissn: false,
                      });
                      // permis = true
                      // console.log("MAIN : ", this.state)

                      // alert("Chnged")
                      // classState(true)
                    } else {
                      this.props.socket.emit("patientCallPermissionIssue", {
                        roomName: this.props.match.params.Roomname,
                        patientPErmissn: true,
                      });
                      this.Permission();
                      dispatch(Actions.RoomTokenExpire(true));
                      // dispatch(Actions.RoomTokenExpire(true))
                      // this.Permission()
                    }
                    permissionStatus.onchange = (e) => {
                      console.log(
                        "geolocation permission state has changed to ",
                        e.currentTarget.state
                      );
                      // if (e.currentTarget.state == "granted") {
                      //   dispatch(Actions.StartCAll(true));
                      // } else {
                      //   this.props.socket.emit("patientCallPermissionIssue", {
                      //     roomName: this.props.match.params.Roomname,
                      //     patientPErmissn: true,
                      //   });

                      //   // dispatch(Actions.RoomTokenExpire(true))
                      //   // this.Permission()
                      // }
                    };
                  });
              } else if (error) {
                dispatch(Actions.StartCAll(false));
                dispatch(Actions.RoomTokenExpire(true));

                this.props.socket.emit("patientCallPermissionIssue", {
                  roomName: this.props.match.params.Roomname,
                  patientPErmissn: true,
                });
              }
            });
        });
        socket.on("mystatus", (data) => {
          console.log("*****", data.doctor);
          this.setState({
            doctorAvailabe: data.doctor != null,
            doctor: data.doctor,
            waitingList: data.waitingList,
            myTurn: data.myTurn,
          });
        });
        socket.on("doctorstatusUpdate", (data) => {
          console.log("*****", data.doctor);
          let waitingList = data.doctor == null ? "0" : this.state.waitingList;
          let myTurn = data.doctor == null ? "-" : this.state.myTurn;
          this.setState({
            doctorAvailabe: data.doctor != null,
            doctor: data.doctor,
            myTurn: waitingList,
            myTurn: myTurn,
          });
        });
        socket.on("newUserArrive", (data) => {
          console.log("*****", data.doctor);
          this.setState({ waitingList: data.waitingList });
        });
        socket.emit("patientLogin", res.data.TokenData);

        this.setState({ Data: res.data, Message: "" });
        dispatch(Actions.patientDataEmailAfterverify(res.data));
        dispatch(Actions.OpenModal(false));
        ///Appoinmrnts Verify
        // Axios.post(`${ROOT_URL}/PatientendVerifyRoom`, {
        //   Roomname: this.props.match.params.Roomname,
        // }).then((resp) => {
        //   if (resp.data.TokenData) {
        //     this.setState({
        //       DrAppoinmentData: resp.data,
        //     });
        //     ///Particpent Add
        Axios.post(`${ROOT_URL}/ParticpentToken`, {
          identity: this.props.PAtientName
            ? this.props.PAtientName.patientFirstName
            : "",
          room: this.props.match.params.Roomname,
        }).then((res) => {
          // console.log("====================================");
          // console.log("dskvbmdsklbmdsklbm", res.data);
          // console.log("====================================");
          this.setState({
            particpent: res.data.token,
            PatientLoginError:""
          });
        });
        //     ///End

        //     this.setState({
        //       EmailVerify: false,
        //     });
        //     dispatch(Actions.DrDataFromPatinet(resp.data));
        //     const socket = io(`${ROOT_URL}`);

        //     });
        //   } else {
        //     this.setState({
        //       Sessionexpired:
        //         resp.data.message == "Not found"
        //           ? "Your Dr is not avaliable"
        //           : resp.data.message,
        //     });
        //   }
        // });
        //End
        ///ParticpentToken

        ///End
        // } else {
        //   this.setState({
        //     appointmentTimeError: "No appointment today",
        //   });
        // }
      } else {
        this.setState({
          Message: res.data.message,
          appointmentTimeError: "",
          ButtonLoader: false,
        });
        // alert("No appointment today")
      }
    }).catch((e) => {
      console.log("hereDataError", e.response.data.message);
      this.setState({
        PatientLoginError: e.response.data.message,
        ButtonLoader: false,
      });

    });
    this.setState({
      // Email: "",
    });
  };
  // Onchage = (e) => {
  //   this.setState({
  //     Email: e.target.value,
  //   });
  // };

  Onchage = (e) => {
    var regex = /\d/g;
    var newcheck = regex.test(e.target.value);
    document.getElementById("abc").focus();
    const { value } = e.target;
    if (newcheck) {
      this.setState(
        {
          Check: true,
          Email: value,
        },
        (e) => {
          document.getElementById("abc").focus();
          const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,100}$/;
          const validateEmail = reg.test(value);

          if (validateEmail) {
            this.setState({
              Email: value,
              emailFlag: false,
              phoneNumber: false,
            });
          } else if (value === "") {
            this.setState({
              Email: value,
              emailFlag: false,
              phoneNumber: false,
            });
          } else {
            this.setState({
              Email: value,
              emailFlag: true,
              phoneNumber: true,
              phoneNumbervalue: value,
            });
          }
        }
      );
    } else {
      this.setState(
        {
          Check: false,
          Email: value,
        },
        () => {
          document.getElementById("abc").focus();
          const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,100}$/;
          const validateEmail = reg.test(value);
          if (validateEmail) {
            this.setState({
              Email: value,
              emailFlag: false,
              phoneNumber: false,
            });
          } else if (value === "") {
            this.setState({
              Email: value,
              emailFlag: false,
              phoneNumber: false,
            });
          } else {
            this.setState({
              Email: value,
              emailFlag: true,
              phoneNumber: true,
              phoneNumbervalue: value,
            });
          }
        }
      );
    }
  };

  onCallEnd = () => {
    // alert("Patient call end")
    let { dispatch } = this.props;
    dispatch(Actions.StartCAll(false));
  };

  onCallDrop = () => {
    let { dispatch } = this.props;
    this.props.socket.emit("forcedisconnect", null);
    dispatch(Actions.StartCAll(false));
    dispatch(Actions.OpenModal(true));
    this.setState({
      snackbar: true,
    });
  };

  // shouldComponentUpdate() {
  // }
  Permission = () => {
    let { dispatch } = this.props;
    // alert("pAtie")

    dispatch(Actions.StartCAll(false));
    // alert("Fb")

    // if (this.props.PermissionDenied == true) {
    //   // this.onCallEnd()
    // }
    // alert("Dr")
  };

  RoomTokenExpire = () => {
    let { dispatch } = this.props;
    dispatch(Actions.StartCAll(false));

    this.onCloseModal();
  };
  Ringing = () => { };
  Muteed = () => {
    this.props.socket.emit("ShowstaticImg", {
      CallMuteRoomname: this.props.match.params.Roomname,
      Mute: true,
    });
    // alert("Patinent")
  };

  popUpHandler = (profilePopUp, e) => {
    console.log("event.....", profilePopUp, e);
    e.stopPropagation();
    this.setState({ profilePopUp });
  };
  TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        guide={false}
        placeholderChar={"\u2000"}
      // showMask
      // id="p-inp3-new"
      // className="p-inp3-new p-inp-new"
      // value={this.props.patientContact}
      // onChange={this.handlePatientContactChange}
      // required
      />
    );
  };

  render() {
    const { classes } = this.props;

    // navigator.permissions.query({ name: 'camera' })
    //   .then((permissionStatus) => {
    //     console.log('geolocation permission state is ', permissionStatus.state);
    //     if (permissionStatus.state == "granted") {
    //       this.props.socket.emit("patientCallPermissionIssue", { roomName: this.props.match.params.Roomname, patientPErmissn: true })
    //     } else {
    //       this.props.socket.emit("patientCallPermissionIssue", { roomName: this.props.match.params.Roomname, patientPErmissn: false })
    //     }
    //     // permissionStatus.onchange = (e) => {
    //     //   console.log('geolocation permission state has changed to ', e.currentTarget.state);
    //     //   if (e.currentTarget.state == "granted") {
    //     //     this.props.socket.emit("patientCallPermissionIssue", { roomName: this.props.match.params.Roomname, patientPErmissn: true })
    //     //   } else {
    //     //     this.props.socket.emit("patientCallPermissionIssue", { roomName: this.props.match.params.Roomname, patientPErmissn: false })
    //     //   }
    //     // };
    //   });

    console.log("hello.....", this.props.cookies.get("practiceAccount"));

    console.log("====================================don", this.state.Message);
    console.log(this.state, this.props);
    console.log("====================================");
    let { dispatch } = this.props;
    // if (this.state.DrAppoinmentData.TokenData) {
    //   console.log(
    //     "propsdsvdsvsdv",
    //     JSON.parse(this.state.DrAppoinmentData.TokenData.roomToken)
    //   );
    // }
    const meta = {
      title: "Patient Management - Increased Patient Engagement | Zebra Doctor",
      description:
        "Offer your patients more convenient ways to consult with you through the all in one Televisit solution by Zebra Doctor.",
      canonical: `${window.location.host}/telemed`,
    };
    const { open, Tab, hover } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <Snackbar
            autoHideDuration={5000}
            open={this.props.ExpireSnackBar}
            onClose={() => dispatch(Actions.ExpireSnackBar(false))}
          >
            <MuiAlert
              severity="warning"
              onClose={() => dispatch(Actions.ExpireSnackBar(false))}
              elevation={6}
              variant="filled"
            >
              Your Session has Expired
            </MuiAlert>
          </Snackbar>

          <Snackbar
            autoHideDuration={5000}
            open={this.state.CancelApt}
            onClose={() => this.setState({ CancelApt: false })}
          >
            <MuiAlert
              severity="warning"
              onClose={() => this.setState({ CancelApt: false })}
              elevation={6}
              variant="filled"
            >
              Dr.{this.state.Data.doctor?.doctorName} cancel your appointment
            </MuiAlert>
          </Snackbar>

          <Snackbar
            autoHideDuration={2000}
            open={this.state.snackbar}
            onClose={() => this.setState({ snackbar: false })}
          >
            <MuiAlert
              severity="warning"
              onClose={() => this.setState({ snackbar: false })}
              elevation={6}
              variant="filled"
            >
              Call dropped by Dr.{this.state.Data.doctor?.doctorName}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            autoHideDuration={3000}
            open={this.props.PermissionDenied}
            onClose={() => dispatch(Actions.RoomTokenExpire(false))}
          >
            <MuiAlert
              severity="error"
              icon={<img src={CrossVideo} />}
              onClose={() => () => dispatch(Actions.RoomTokenExpire(false))}
              elevation={6}
              variant="filled"
            >
              We can't access your camera and microphone. Please ensure you have
              both a camera and mic and have allowed {ROOT_URL} to access them.
            </MuiAlert>
          </Snackbar>
          <>
            {/* {this.props.StartCall !== true ? (
            <Header logout={this.onCloseModal} />
          ) : (
            ""
          )}
          {this.props.StartCall !== true ? <Menubar /> : ""}

          {console.log("&&&&&", this.props.StartCAll)} */}
            <div
              className={clsx(
                (open === true && hover === true) ||
                  (open === true && hover === false)
                  ? classes.root
                  : classes.newRoot
              )}
            >
              {this.props.StartCall !== true && (
                <>
                  <CssBaseline />
                  <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                      [classes.appBarShift]: open,
                    })}
                  >
                    <Toolbar>
                      <div
                        className={clsx("tb_imageContainer", {
                          [classes.hideNew]: open,
                        })}
                      >
                        <img src={logoWhite} alt="" />
                      </div>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                          [classes.hide]: open,
                        })}
                      >
                        <MenuIcon />
                      </IconButton>
                      <div style={{ flexGrow: 1 }}></div>
                      {/* <IconButton color="inherit">
                      <Notifications
                        fontSize="small"
                        className="tb_notificationIcon"
                      />
                    </IconButton> */}
                      <div
                        className="tb_profileContainer"
                        onClick={(e) => this.popUpHandler(true, e)}
                      >
                        <p className="tb_para1">
                          {this.props.Open == true
                            ? ""
                            : this.state.PatientData?.patientFirstName}
                        </p>
                        <div className="tb_imageContainer1">
                          <img src={profile} alt="" />
                        </div>
                        <div
                          className={
                            this.state.profilePopUp
                              ? "tb_profilePopUp"
                              : "tb_profilePopUp_hide"
                          }
                        >
                          <span
                            class="material-icons tb_ClearIcon"
                            onClick={(e) => this.popUpHandler(false, e)}
                          >
                            clear
                          </span>
                          <div className="tb_imageContainer1">
                            <img src={profileBlack} alt="" />
                          </div>
                          <p className="tb_Name">
                            {this.state.PatientData?.patientFirstName}
                          </p>
                          <hr />
                          <p
                            className="tb_para2"
                            onClick={(e) => this.logoutHandler(e)}
                          >
                            Logout
                          </p>
                        </div>
                      </div>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpenNew}
                        edge="start"
                        className={clsx(classes.menuButtonNew)}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <Drawer
                    onMouseOver={this.handlerMouseOver}
                    onMouseOut={this.handlerMouseOut}
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    <div className={classes.toolbar}>
                      <div className="tdc_logoContainer">
                        <div className="tdc_imageContainer">
                          <img src={logoImage} alt="" />
                        </div>
                        <div className="tdc_logoContent">
                          <p className="tdc_p1">Zebra Doctor</p>
                          <p className="tdc_p2">Medical Stack</p>
                        </div>
                      </div>
                      <IconButton onClick={this.handleDrawerClose}>
                        {this.props.theme.direction === "rtl" ? (
                          <ChevronRightIcon />
                        ) : (
                          <ChevronLeftIcon />
                        )}
                      </IconButton>
                    </div>
                    <Divider />
                    <List>
                      {["Dashboard", "Help"].map((text, index) => (
                        <ListItem
                          onClick={() => this.Tabchange(text)}
                          button
                          key={text}
                        >
                          <ListItemIcon className={classes.iconStyle}>
                            {index % 2 === 0 ? <Dashboard /> : <Help />}
                          </ListItemIcon>
                          <ListItemText
                            className={classes.textStyle}
                            primary={text}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </>
              )}

              <main
                className={
                  this.props.StartCall !== true
                    ? classes.content
                    : classes.content1
                }
              >
                {this.props.StartCall !== true && (
                  <div className={classes.toolbar} />
                )}
                {Tab == "Dashboard" ? (
                  <>
                    {
                      this.props.StartCall === true ? (
                        <VideoCall
                          cookies={this.props.cookies}
                          dispatch={dispatch}
                          roomName={this.props.match.params.Roomname}
                          token={this.state.particpent}
                          onCallEnd={this.onCallEnd}
                          onCallDrop={this.onCallDrop}
                          Permission={this.Permission}
                          Ringing={this.Ringing}
                          RoomTokenExpire={this.RoomTokenExpire}
                          Muteed={this.Muteed}
                          socket={this.props.socket}
                        />
                      ) : this.props.Open == true ? (
                        <div></div>
                      ) : (
                        <MainPatiendScreePatientDashboard
                          DoctorData={this.state.DoctorData}
                          waitingList={this.state.waitingList}
                          myTurn={this.state.myTurn}
                          doctor={this.state.doctor}
                          doctorAvailabe={this.state.doctorAvailabe}
                          socket={this.props.socket}
                        />
                      )
                      // <MainDashboard />
                    }
                    <Modal
                      open={this.props.Open}
                      showCloseIcon={false}
                      onClose={this.Cantclose}
                      center
                      className="tda_modal"
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 1000,
                      }}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      <Fade in={this.props.Open}>
                        <div className="tda_modal_content">
                          <div className="tda_sec1">
                            <p className="tda_sec1_p1">Televisit</p>
                            <p className="tda_sec1_p2">
                              Login to your Televisit room
                            </p>
                            <div className="tda_sec1_imageContainer">
                              <img src={camera} alt="" />
                            </div>
                          </div>
                          <div className="tda_form">
                            <TextField
                              // onBlur={() => { this.setState({ appointmentTimeError: "" }) }}
                              onClick={() => {
                                this.setState({
                                  Message: "",
                                  appointmentTimeError: "",
                                  phoneNumber: false,
                                });
                              }}
                              onChange={this.Onchage}
                              id="abc"
                              label="Email"
                              required
                              variant="outlined"
                              value={this.state.Email}
                              // InputProps={
                              //   this.state.Check
                              //     ? {
                              //         inputComponent: this.TextMaskCustom,
                              //       }
                              //     : {}
                              // }
                              onKeyPress={
                                this.state.emailFlag || this.state.Email === ""
                                  ? null
                                  : (event) => {
                                    if (event.key === "Enter") {
                                      this.Submit();
                                    }
                                  }
                              }
                            // InputProps={
                            //   this.state.phoneNumber
                            //     ? {
                            //         inputComponent: TextMaskCustom,
                            //       }
                            //     : {}
                            // }
                            />
                            {/* <span
                            className="tda_span1"
                            style={{
                              display: this.state.emailFlag ? "block" : "none",
                            }}
                          >
                            {this.state.emailErrorMsg}
                          </span> */}
                            <span
                              className="tda_span1"
                              style={{
                                display:
                                  this.state.PatientLoginError == ""
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {this.state.PatientLoginError}
                            </span>
                            {/* <span
                              className="tda_span1"
                              style={{
                                display:
                                  this.state.appointmentTimeError === null ||
                                  this.state.appointmentTimeError === "" ||
                                  this.state.appointmentTimeError === undefined
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {this.state.appointmentTimeError}
                            </span>
                            <span
                              className="tda_span1"
                              style={{
                                display:
                                  this.state.Sessionexpired === null ||
                                  this.state.Sessionexpired === "" ||
                                  this.state.Sessionexpired === undefined
                                    ? "none"
                                    : "block",
                              }}
                            >
                              {this.state.Sessionexpired}
                            </span> */}
                            {/* <button
                        onClick={this.Submit}
                        style={{ opacity: this.state.Email == "" ? 0.5 : 1 }}
                        disabled={this.state.Email == "" ? "disabled" : ""}
                      >
                        Continue
                      </button> */}
                            <Button
                              className={
                                this.state.Email === ""
                                  ? "tda_btn1_disable"
                                  : "tda_btn1"
                              }
                              onClick={this.Submit}
                              type="button"
                              // style={{ opacity: this.state.Token == "" ? 0.5 : 1 }}
                              disabled={this.state.Email === "" ? true : false}
                            >
                              {this.state.ButtonLoader == true ? (
                                <CircularProgress
                                  style={{
                                    height: 30,
                                    width: 30,
                                    color: "#fff",
                                  }}
                                />
                              ) : (
                                "Login"
                              )}
                            </Button>
                            {/* <div
                        className="FormLoader"
                        style={{ position: "relative", top: 22, left: 90 }}
                      >
                        {this.state.ButtonLoader == true ? <ButtonLoader /> : ""}
                      </div> */}
                          </div>
                        </div>
                      </Fade>
                    </Modal>
                  </>
                ) : Tab == "Help" ? (
                  <HelpCenter />
                ) : null}
              </main>
            </div>
          </>
        </div>
      </DocumentMeta>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("====================================");
  console.log(state);
  console.log("====================================");
  return {
    ...state,
    Open: state.TelemedOpenmodal.Open,
    PermissionDenied: state.TelemedOpenmodal.RoomTokenExpire,
    CallData: state.TelemedOpenmodal.DrDataFromPatinet,
    StartCall: state.TelemedOpenmodal.StartCall,
    PAtientName: state.TelemedOpenmodal.patientDataEmailAfterverify.TokenData,
    ExpireSnackBar: state.TelemedOpenmodal.ExpireSnackBar,
  };
};
export default withTheme(
  withStyles(styles)(withRouter(connect(mapStateToProps)(Doctor)))
);
