import React from "react";
import "../../css/components/_ButtonLoader.scss";
function ButtonLoader({ text, color, newclassName = "bl-btn1" }) {
  return (
    <button className={newclassName} style={{ backgroundColor: color, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <i style={{ marginLeft: 1 }} className="fa fa-spinner fa-spin bl-icon1" />
      {text}
    </button>
  );
}

export default ButtonLoader;
