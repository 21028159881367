
import { combineReducers } from "redux";
import TelemedOpenmodal from "./TelemedOpenmodal";
import TelemedicineDocotorAuthReducer from "../components/Telemedicine/TelemedDoctorEnd/TelemedDoctorAuth/TelemedDoctor_Reducer";
import PracticeSessionExpired from "./PracticeSessionExpired";

const appReducer = combineReducers({
  TelemedOpenmodal,
  TelemedicineDocotorAuthReducer,
  PracticeSessionExpired,
});
const rootReducer = (state, action) => {
  if (action.type === "PRACTICEACCOUNT_LOGOUT") {
    console.log("loging user out");
    state = undefined;
  }
  // if (action.type === "TIKCETSBYPRACTICE_ALL_SUCCESS") {
  //   state = undefined;
  // }

  return appReducer(state, action);
};
export default rootReducer;
