const initialState = {
  PracticeSessionExpired: false,
};

const PracticeSessionExpired = (state = initialState, action) => {
  console.log("====================================");
  console.log("action", action);
  console.log("====================================");
  switch (action.type) {
    case "SessionExpired":
      return {
        PracticeSessionExpired: action.payload,
      };
    default:
      return { ...state };
  }
};

export default PracticeSessionExpired;
