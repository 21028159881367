import firebase from 'firebase/app';

export const initializeFirebase = () => {
  if(!firebase.apps.length)
  {

  firebase.initializeApp({
    messagingSenderId: "61290972663"
  });
  if(navigator.serviceWorker){
  navigator.serviceWorker
    .register('./serviceWorker.js')
    .then((registration) => {
      firebase.messaging().useServiceWorker(registration);
    });
  }
  else
  {
    console.log("service worker not supported")
  }
  }
}
export const askForPermissioToReceiveNotifications = async () => {
    try {
      if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
  
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('user token: ', token);
  
      return token;
    } else{
      alert("Notifications Unsupported")
      return 
    }
  }
  catch (error) {
      console.error(error);
    }
  }