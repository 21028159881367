import React, { useState, useEffect, useCallback } from "react";
import Video, {
  createLocalVideoTrack,
  createLocalAudioTrack,
  connect,
} from "twilio-video";
import Participant from "./Praticepent";
import * as Actions from "../../servers/Telemed";
import Loader from "../utils/SingleLoader";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { ROOT_URL } from "../../constants/config";
const Room = ({
  roomName,
  onCallEnd,
  token,
  dispatch,
  onCallDrop,
  Permission,
  RoomTokenExpire,
  socket,
  Muteed,
  cookies,
}) => {
  console.log(token);

  const [snack, setsnack] = useState(false);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [MuteCamera, setMuteCamera] = useState(true);
  const [MuteMic, setMuteMic] = useState(true);
  const [ring, setring] = useState(
    "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
  );
  const muteMycamera = useCallback(
    (MuteVideo) => {
      console.log(MuteVideo, room);
      if (MuteVideo) {
        const track = [...room.localParticipant.videoTracks.values()][0];
        console.log(MuteVideo, track);
        if (track != undefined)
          room.localParticipant.unpublishTrack(track.track);
      } else {
        const track = [...room.localParticipant.videoTracks.values()][0];
        console.log(track);
        if (track == undefined) {
          createLocalVideoTrack().then((track) => {
            room.localParticipant.publishTrack(track);
          });
        }
      }
      setMuteCamera(!MuteCamera);
    },
    [!MuteCamera, room]
  );

  const OnDisconnect = useCallback(() => {
    room.disconnect();
  }, [room]);

  const muteMyMic = useCallback(
    (MuteAudio) => {
      console.log(MuteAudio, room);
      if (MuteAudio) {
        const track = [...room.localParticipant.audioTracks.values()][0];
        console.log(MuteAudio, track);
        if (track != undefined)
          room.localParticipant.unpublishTrack(track.track);
      } else {
        // alert("Mite")
        createLocalAudioTrack().then((track) => {
          room.localParticipant.publishTrack(track);
        });
      }
      setMuteMic(!MuteAudio);
    },
    [!MuteMic, room]
  );
  const remoteParticipants = participants.map((participant) => (
    <Participant
      dispatch={dispatch}
      key={participant.sid}
      participant={participant}
      muteMycamera={muteMycamera}
      MuteCamera={MuteCamera}
      MuteMic={MuteMic}
      muteMyMic={muteMyMic}
      OnDisconnect={OnDisconnect}
      roomName={roomName}
      Muteed={Muteed}
      socket={socket}
      cookies={cookies}
    />
  ));

  useEffect(() => {
    const participantConnected = (participant) => {
      console.log("participant",participant);
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      console.log("here disconnected", participant, room);
      //remove user from room, if his participant is left
      if (room != null && room != undefined) room.disconnect();
      else onCallDrop();
      // setParticipants((prevParticipants) =>
      //   prevParticipants.filter((p) => p !== participant)
      // );
    };

    connect(token, {
      name: roomName,
      audio: true,
      maxAudioBitrate: 16000,
      region: "gll",
      video: { width: 640, frameRate: 24 },
    })
      .then((room) => {
        setRoom(room);
        console.log("====================================");
        console.log("dsfjndsjfds", room);
        console.log("====================================");
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);

        room.participants.forEach(participantConnected);
        window.addEventListener("beforeunload", () => {
          room.disconnect();
        });

        room.on("participantReconnecting", (remoteParticipant) => {
          console.log("reconneting");
        });

        room.on("disconnected", (room, error) => {
          console.log("dsvewfv", room, error);
          if (room.state == "disconnected");
          {
            room.disconnect();
            onCallEnd();
          }
          if (error != undefined) {
            // room.disconnect()
            console.log("here error wait", error, error.code);

            if (error.code === 20104) {
              Permission();
              // setsnack(true)
              console.log(
                "Signaling reconnection failed due to expired AccessToken!"
              );
            } else if (error.code === 53000) {
              Permission();
              // setsnack(true)
              console.log("Signaling reconnection attempts exhausted!");
            } else if (error.code === 53204) {
              Permission();
              // setsnack(true)
              console.log("Signaling reconnection took too long!");
            } else if (error.code == 53205) {
              // token
              // room.sid
              Permission();
            }
          }
        });
      })
      .catch((e) => {
        if (e) {
          console.log("here error", e.message);
          if (e.message == "Failed starting capture of a video track") {
            Permission();
          }
          if (e.message.toLowerCase().includes("permission")) {
            Permission();
            // dispatch(Actions.RoomTokenExpire(true))

            // onCallDrop()
          }
          if (
            e.message.toLowerCase().includes("invalid access token") ||
            e.message.includes("expire")
          ) {
            dispatch(Actions.RoomTokenExpire(true));

            RoomTokenExpire();
          }
        }
      });
    // socket.on("patientCallPermissionIssue", data => {
    //   console.log("patientCallPermissionIssue", room)
    //   if (room != null && room != undefined) {
    //     room.disconnect();
    //   }
    //   else {
    //     onCallDrop()
    //     dispatch(Actions.RoomTokenExpire(true))
    //   }
    // })
console.log("remoteParticipants",remoteParticipants);
    return () => {
      setRoom((currentRoom) => {
        console.log("returm", currentRoom);

        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  // setTimeout(() => {
  //   setsnack(true)
  // }, 5000);
  // let ringing = new Audio(ring);
  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        open={snack}
        onClose={() => setsnack(false)}
      >
        <MuiAlert
          severity="error"
          onClose={() => setsnack(false)}
          elevation={6}
          variant="filled"
        >
          Your Session Expired
        </MuiAlert>
      </Snackbar>
      <div className="room">
        {/* <h2>RoomName: {roomName}</h2> */}
        {/* <button onClick={handleLogout}>Log out</button> */}
        <div className="local-participant">
          {/* {room ? (
          <Participant
          dispatch={dispatch}
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          />
          ) : (
            ""
          )} */}
        </div>
        {
          remoteParticipants.length > 0 ? (
            <div className="remote-participants">{remoteParticipants}</div>
          ) : (
            <div style={{ position: "relative", height: "100vh" }}>
              {/* {ringing.play()} */}
              <Loader title="Finalizing connection, please stand by" />
              {/* // <h1>sabir here</h1> */}
            </div>
          )
          // <h3> Connecting...</h3>
        }
      </div>
    </React.Fragment>
  );
};

export default Room;
