import React, { Component } from "react";
import "../../../css/components/_MenuBar.scss";
import HelpImage from "../../../images/24px (2).svg"
export class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <nav className="navigation2">
        <div className="mb-div1">
          <i
            className={`material-icons mb-icon ${this.props.Tab == "Dashboard" ? "mb-icon1" : ""}`}
            title="Inquiries"
            style={{
              //   borderLeft: this.props.border1,
              //   backgroundColor: this.props.color1
            }}
            onClick={() => {
              this.props.Tabchange("Dashboard");
            }}
          >
            dashboard
          </i>
          {/* <img src={HelpImage} 
          onClick={() => {
            this.props.Tabchange("HelpCenter");
          }}
            className={`material-icons mb-icon ${this.props.Tab == "HelpCenter" ? "mb-icon1" : ""}`}


          // style={{ height: 40, width: 40 }}
          /> */}
          <i
            // title="Appointments"
            style={{
              //   borderLeft: this.props.border2,
              //   backgroundColor: this.props.color2
            }}
            onClick={() => {
              this.props.Tabchange("HelpCenter");
            }}
            className={`material-icons mb-icon ${this.props.Tab == "HelpCenter" ? "mb-icon1" : ""}`}

          // onClick={() => {
          //   this.props.onAppointmentsClick();
          // }}
          >
            help_outline
          </i>
        </div>
      </nav>
    );
  }
}

export default MenuBar;
