import { connect } from "react-redux";
import SessionExp from "./index";

//import {PatientProfile_Action} from "../constants/PatientProfile"
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const AppoinmentStatusContainer = connect(mapStateToProps)(SessionExp);

export default AppoinmentStatusContainer;
