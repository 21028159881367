import ReactGA from 'react-ga';
import React from 'react';

class GoogleAnalyticsManager extends React.Component {
    constructor()
    {
        super();
        

    }
    componentWillMount()
    {
        console.log("running GA",this.props.GATrackingCode)
       ReactGA.initialize(this.props.GATrackingCode);
       ReactGA.pageview(window.location.pathname + window.location.search);
    }
 
    render() {
      
 
        return (
           <div></div>
        );
    }
}
 

 
export default GoogleAnalyticsManager;