import React from "react";
import moment from "moment";

function MedicalPracticeDetails({ appDetailHandler, PatientData, appDetail }) {
  return (
    <div className="participent_patientDetails">
      <p
        className="participent_patientDetails_p1"
        onClick={() => appDetailHandler(true)}
      >
        Appointment Details
      </p>
      <div
        className={
          appDetail
            ? "participent_patientDetails_content"
            : "participent_patientDetails_contentHide"
        }
      >
        <span
          class="material-icons participent_patientDetails_Icon"
          onClick={() => appDetailHandler(false)}
        >
          clear
        </span>
        <h3 className="participent_patientDetails_h1">
          Appointment Information
        </h3>
        <div className="participent_patientDetails_appInfo">
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Date</p>
            <p className="participent_patientDetails_p3">
              {moment(PatientData.appointmentDate.substring(0, 10)).format(
                "MM/DD/YYYY"
              )}
            </p>
          </div>
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Time</p>
            <p className="participent_patientDetails_p3">
              {moment(PatientData.appointmentTime).format(
                "hh:mm A"
              )}
              {/* {moment(JSON.parse(PatientData.appointmentTime)).format(
                "hh:mm A"
              )} */}
            </p>
          </div>
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Reason</p>
            <p className="participent_patientDetails_p3">
              {PatientData.reasonForVisit}
            </p>
          </div>
        </div>
        <h3 className="participent_patientDetails_h1">Personal Information</h3>
        <div className="participent_patientDetails_appInfo">
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Name</p>
            <p className="participent_patientDetails_p3">
              {PatientData.patientFirstName} {PatientData.patientLastName}
            </p>
          </div>
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Email</p>
            <p className="participent_patientDetails_p3">
              {PatientData.patientEmail}
            </p>
          </div>
          <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Phone</p>
            <p className="participent_patientDetails_p3">
              {PatientData.patientContact}
            </p>
          </div>
          {/* <div className="participent_patientDetails_appContent">
            <p className="participent_patientDetails_p2">Date of Birth</p>
            <p className="participent_patientDetails_p3">
              {PatientData.patientDob
                ? moment(PatientData.patientDob).format("MM/DD/YYYY")
                : null}
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MedicalPracticeDetails;
