import React, { Component } from "react";
import * as Actions from "../../../servers/Telemed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../../../css/components/_TelemedMainDashBoard.scss";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import { Link } from "react-router-dom";
// import Axios from "axios";
import UnSuppoertBrowser from "./UnsupportedBrowser";
import PatientPermission from "./PatientPermession";
import Img from "../../../images/personProfileImage.png";
import Img1 from "../../../images/personProfileImageBlack.png";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Token: "",
      Data: {},
      Message: "",
      ExpiredSnack: false,
      unsupportedBrowser: true,
    };
    const { dispatch } = props;
    this.boundActionCreators = bindActionCreators(Actions, dispatch);
  }
  //   onCloseModal = () => {
  //     let { dispatch } = this.props;
  //     dispatch(Actions.OpenModal(false));
  //   };
  BrwsernotSupporter = (error) => {
    this.setState({
      unsupportedBrowser: error,
    });
  };

  componentDidMount() {
    this.PatientInterval = setInterval(() => {
      this.PatinetExpireChexked();
    }, 86400);
  }
  componentWillUnmount() {
    clearInterval(this.PatientInterval);
  }

  async PatinetExpireChexked() {
    let { dispatch } = this.props;
    let Expire = await localStorage.getItem("PatientExpire");
    let A = JSON.parse(Expire);
    // console.log("dsvrgnwieugbn", A.Time);
    // console.log("dvsdtuhtnmh", moment(A.Time).subtract(2, 'seconds'));

    // console.log(this.getTime(A.Time));
    if (this.getTimpatiente(A?.Time) == true) {
      this.props.socket.emit("forcedisconnect", null);
      dispatch(Actions.ExpireSnackBar(true));
      dispatch(Actions.OpenModal(true));
      dispatch(Actions.StartCAll(false));
      this.setState({
        ExpiredSnack: true,
      });
      // alert("Your Session has been Expired");
    }
    // if (moment(Expire.Time).subtract(20, 'seconds')) {
    // alert("dsdvvdvn")
    // }
  }
  getTimpatiente(time) {
    let datee = new Date();
    return (
      moment(datee.getTime()).subtract(60, "minutes").format("hh:mm") >=
      moment(time).subtract(0, "minutes").format("hh:mm")
    );
  }

  startcall = () => {
    let { dispatch } = this.props;
    dispatch(Actions.StartCAllForPatientend(true));
  };
  render() {
    console.log("this.props.DoctorData....", this.props.DoctorData);
    return this.state.unsupportedBrowser ? (
      <div className="container" style={{ display: "inline" }}>
        {/* <Snackbar
          autoHideDuration={5000}
          open={this.state.ExpiredSnack}
          onClose={() => this.setState({ ExpiredSnack: false })}
        >
          <MuiAlert
            severity="warning"
            onClose={() => this.setState({ ExpiredSnack: false })}
            elevation={6}
            variant="filled"
          >
            Your Session has been Expired
          </MuiAlert>
        </Snackbar> */}

        {/* Link */}
        {/* <h1></h1> */}
        <div className="Conta dr-available">
          <div className="Wrapper">
            <div className="MenuBox">
              <div className="imgstatus">
                <img
                  src={
                    this.props.DoctorData?.doctorImage
                      ? this.props.DoctorData.doctorImage
                      : // Img1
                        Img
                  }
                  alt=""
                />
                <div
                  className="status"
                  style={{
                    backgroundColor:
                      this.props.doctorAvailabe == false
                        ? "#FFBB00"
                        : "#0ECF00",
                  }}
                ></div>
              </div>
              <div className="Patient-text">
                <h1>
                  Dr. {console.log("dsvdsvsdsv", this.props.DoctorData)}
                  {this.props.DoctorData
                    ? this.props.DoctorData.doctorName
                    : this.props.doctor == "null" || this.props.doctor == null
                    ? ""
                    : this.props.doctor.doctorName}
                  {/* {this.props.doctor == "null" || this.props.doctor == null ? "" : this.props.doctor.doctorName} */}
                </h1>
                <span>{this.props.DoctorData.doctorSpecialization}</span>
                {/* <div className="doctorHistory">
                  <div className="doctorContent">
                    <p className="doctor_p1">12</p>
                    <p className="doctor_p2">PATIENTS</p>
                  </div>
                  <div className="doctorContent">
                    <p className="doctor_p1">32</p>
                    <p className="doctor_p2">TELEMED HOURS</p>
                  </div>
                  <div className="doctorContent">
                    <p className="doctor_p1">4</p>
                    <p className="doctor_p2">REVIEWS</p>
                  </div>
                </div> */}
              </div>
              {/* <button onClick={this.startcall} className="BtnCall">Join Call</button> */}
            </div>
            <div className="doctorInformation">
              <div className="topContent">
        
              </div>
              <div className="bottomContent">
                <div className="doctorContent">
                  {/* <p className="doctorContent_p1">About me</p> */}
                  {/* <p className="doctorContent_p2">
                    {this.props.DoctorData.doctorDescription}
            
                  </p> */}
                </div>
              </div>
            </div>

            <PatientPermission BrwsernotSupporter={this.BrwsernotSupporter} />
            {/* <div className="QueBox"> */}
            {/* <h1>{this.props.waitingList != "-" ? this.props.waitingList + " people in this waiting room" : ""} </h1> */}
            {/* <h2> {this.props.myTurn != "-" ? "You are " + this.props.myTurn + " in queue" : ""}</h2> */}
            {/* <div className="Time">
                <h3>Estimated time</h3>
                <h4> {this.props.myTurn != "-" && this.props.doctorAvailabe ? (15 * this.props.myTurn) + " minutes" : "-"} </h4>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    ) : (
      <UnSuppoertBrowser />
    );
  }
}
const mapStateToProps = (state) => {
  console.log("====================================");
  console.log(state);
  console.log("====================================");
  return {
    ...state,
    DrData: state.TelemedOpenmodal.DrDataFromPatinet,
    // AuthData: state.TelemedOpenmodal.AuthDataRoom,
  };
};
export default connect(mapStateToProps)(Dashboard);
