import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import Privacyimf from "../../images/security-24px.png";
import { Button } from "@material-ui/core";
import "./PrivacyTest.scss";
export default class PrivacyTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Step: 1,
      Open: true,
    };
  }
  handelclick = (v) => {
    if (v == 3) {
      this.setState(
        {
          Open: false,
        },
        () => {
          this.props.PatiendendMuteVideo();
          this.props.Mute();
        }
      );
    } else {
      this.setState(
        {
          Step: v,
        },
        () => {
          this.props.PatiendendMuteVideo();
          this.props.Mute();
        }
      );
    }
  };
  render() {
    return (
      <Modal
        open={this.state.Open}
        // onClose={handleClose}
        className="Modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="parentPrivacyimf">
          {this.state.Step == 1 ? (
            <div className="Privacyimf">
              <img src={Privacyimf} />
              <h1>Content Privacy</h1>
              <p>
                Your Call is end-to-end encrypted. All media published to or
                subscribed from the Room is sent over secure connections
              </p>
              <Button
                onClick={() => this.handelclick(2)}
                className="PrivacyimfNextbtn"
              >
                Next
              </Button>
            </div>
          ) : (
            <div className="Privacyimf">
              <img src={Privacyimf} />
              <h1>Communication Privacy</h1>
              <p>
                This video is not being recorded by zebra Doctor. All
                communication is only between doctor and patient
              </p>
              <Button
                onClick={() => this.handelclick(3)}
                className="PrivacyimfNextbtn"
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
