import React, { Component } from "react";
//import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
// import staticiamge from "../../../../images/Ellipse-116.png";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import ConfirmationModal from "../ConfirmationModal";
import emptyRoom from "../../../../images/emptyRoom.png";
import "./Style.scss";
const styles = (theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 0,
    height: "64vh",
    overflowX: "auto",
    border: "1px solid #dfdfdf",
    backgroundColor: "white",
  },
  div: {
    // width: "100%",
    // // height: '72vh',
    // overflowX: "auto",
    border: "1px solid #000",
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    borderBottom: "1px solid #dfdfdf",
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Open Sans",
    letterSpacing: "1px",
  },
  size: {
    fontSize: "14px",
    fontWeight: 500,
    // border: '1px solid black',
    whiteSpace: "nowrap",
  },
  newSize: {
    fontSize: "14px",
    fontWeight: 500,
    // border: '1px solid black',
    // whiteSpace: "nowrap",
  },
});
class TableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "#015EFF",
      appointments: [],
      //..............
      doctors: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      //..............
      practice: [],
      FilterStatus: "1234",
      status: "",
      isFetching: false,
      Data: this.props.AppoinmentData,
    };
  }
  componentDidUpdate(prevprops) {
    if (prevprops.SeachValue !== this.props.SeachValue) {
      if (this.props.SeachValue !== "") {
        let array = this.props.AppoinmentData;

        this.setState({
          Data: array.filter((arr) =>
            arr.data.patientFirstName.includes(this.props.SeachValue)
          ),
        });
      } else {
        this.setState({
          Data: this.props.AppoinmentData,
        });
      }
    }
  }
  render() {
    console.log("====================================");
    console.log(this.props.SeachValue);
    console.log("====================================");
    console.log("====================================");
    console.log("AppoinmentData....", this.props.AppoinmentData[0]?.data);
    console.log("====================================");
    console.log("====================================");
    console.log("FilterData....", this.state.Data);
    console.log("====================================");
    const { classes } = this.props;
    const FilterStatus = this.props.SeachValue;
    return (
      <div className={classes.root}>
        {/* <Paper className={classes.root}> */}
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell align="left" className={classes.tableCell}>
                NAME
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                EMAIL
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                PHONE
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                DOB
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                DATE
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                TIME
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                REASON
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.AppoinmentData.length > 0 &&
              // .filter(function (appointmentArray) {
              //   return FilterStatus.includes(
              //     appointmentArray.doctorName.toString()
              //   );
              // })

              this.props.AppoinmentData.map((res, index) => {
                console.log("====================================");
                console.log("dsvsdv11",res);
                console.log("====================================");
                return (
                  <>
                    <TableRow id="table-row" key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {/* <div className="Name">
                        <div className="RoundImage">
                          <img src={v?.doctorImage} alt="" />
                        </div>
                        <p>{res.data.patientFirstName}</p>
                        </div> */}
                        {res.data.patientFirstName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {res.data.patientEmail}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {res.data.patientContact}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {res.data.patientDob
                          ? moment(res.data.patientDob).format("MM/DD/YYYY")
                          : ""}
                        {/* {moment(res.data.patientDob).format("MM/DD/YYYY")} */}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {moment(
                          res.data.appointmentDate.substring(0, 10)
                        ).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {typeof res.data.appointmentTime=="string"? moment(res.data.appointmentTime).format(
                          "hh:mm A"
                        ): moment(JSON.parse(res.data.appointmentTime)).format(
                          "hh:mm A"
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        {res.data.reasonForVisit}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.size}
                      >
                        <div className="All-doctor-Action_TableList">
                          <button
                            // disabled={v?.roomToken == null ? true : false}
                            // style={{
                            //   background: v?.roomToken == null ? "grey" : "",
                            //   border:
                            //     v?.roomToken == null ? "1px solid grey" : "",
                            // }}
                            className="call_btn"
                            onClick={() => this.props.startcall(res.id, res)}
                          >
                            START CALL
                          </button>
                          <button
                            // onClick={() =>
                            //   this.props.cancelAppointmentHandler(index)
                            // }
                            onClick={() =>
                              this.props.ToggleConfirmationModal(index)
                            }
                            className="call_btn"
                          >
                            CANCEL APT
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                    {this.props.selectedIndex === index && (
                      <ConfirmationModal
                        ToggleConfirmationModal={
                          this.props.ToggleConfirmationModal
                        }
                        cancelAppointmentHandler={
                          this.props.cancelAppointmentHandler
                        }
                        selectedIndex={this.props.selectedIndex}
                        disconnectSocket={this.props.disconnectSocket}
                        onCallEnd={this.props.onCallEnd}
                      />
                    )}
                  </>
                );
              })}
          </TableBody>
        </Table>
        {this.props.AppoinmentData.length === 0 && (
          <div className="emptyRoom_imageContainer">
            <img src={emptyRoom} alt="" />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TableList);
