import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";
import Notifications from "@material-ui/icons/Notifications";
import logoImage from "../../../../images/lpage3Logo.png";
import logoWhite from "../../../../images/lpage3LogoWhite.png";
import profile from "../../../../images/personProfileImage.png";
import profileBlack from "../../../../images/personProfileImageBlack.png";
import "./TelemedDoctorContainer.scss";
// .........
import { connect } from "react-redux";
import { DoctorEndServer } from "../../TelemedDoctorEnd/server";
import { bindActionCreators } from "redux";
import * as Actions from "../../../../servers/Telemed";
import HelpCenter from "../../TelemedDoctorEnd/HelpCenter";
import TelemedDoctorAuthContainer from "../../TelemedDoctorEnd/TelemedDoctorAuth/TelemedDoctorAuthContainer";
import DocumentMeta from "react-document-meta";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    ["@media (max-width:1080px)"]: {
      display: "block",
    },
  },
  newRoot: {
    display: "block",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ["@media (max-width:1080px)"]: {
      marginLeft: 0,
      width: `100%`,
    },
  },
  menuButton: {
    marginRight: 36,
    ["@media (max-width:1080px)"]: {
      display: "none",
    },
  },
  menuButtonNew: {
    display: "none",
    marginLeft: 10,
    marginRight: -10,
    ["@media (max-width:1080px)"]: {
      display: "flex",
    },
    ["@media (max-width:360px)"]: {
      marginLeft: 2,
    },
  },
  hide: {
    display: "none",
  },
  hideNew: {
    display: "none",
    ["@media (max-width:1080px)"]: {
      display: "flex",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 10,
    "&:hover": {
      width: drawerWidth,
    },
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9) + 1,
    // },
    ["@media (max-width:1080px)"]: {
      width: 0,
      "&:hover": {
        width: 0,
      },
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    ["@media (max-width:450px)"]: {
      padding: theme.spacing(3),
    },
  },
  content1: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  iconStyle: {
    color: "#000",
  },
  textStyle: {
    // fontFamily: "Open Sans",
    // fontSize: "18px",
    // fontWeight: "700",
    // color: "blue",
  },
}));

function TelemedDoctorContainer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [profilePopUp, setProfilePopUp] = React.useState(false);
  const [Tab, setTab] = React.useState("Dashboard");

  const logout = () => {
    props.socket.emit("forcedisconnect", null);

    localStorage.removeItem("DrId", { path: "/doctor" });
    props.logoutDoctor(props);
    setTab("Dashboard");
    setProfilePopUp(false);
  };

  const logoutHandler = (e) => {
    e.stopPropagation();
    props.socket.emit("forcedisconnect", null);

    localStorage.removeItem("DrId", { path: "/doctor" });
    props.logoutDoctor(props);
    setTab("Dashboard");
    setProfilePopUp(false);
  };

  const Tabchange = (Name) => {
    setTab(Name);
  };

  const popUpHandler = (profilePopUp, e) => {
    console.log("event.....", profilePopUp, e);
    e.stopPropagation();
    setProfilePopUp(profilePopUp);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenNew = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlerMouseOver = () => {
    setHover(true);
  };

  const handlerMouseOut = () => {
    setHover(false);
  };

  let { doctor } = props;
  console.log("====================================");
  if (props.cookies.get("doctor") != undefined) {
    doctor = props.cookies.get("doctor");
  }
  console.log(
    "=======================fvdsvdsvdsv=============",
    props.cookies.get("doctor")
  );
  console.log(
    "=======================fvdsvdsvdsv props.socket=============",
    props.socket
  );

  const doctorInfo =  JSON.parse(localStorage.getItem("DrId"));
  console.log("props.StartCall.....",doctorInfo);

  const meta = {
    title: "Televisit Solution - Integrated and Easy to Use | Zebra Doctor",
    description:
      "Offer your patients more convenient ways to consult with you through the all in one Televisit solution by Zebra Doctor.",
    canonical: `${window.location.host}/doctor`,
  };
  return (
    <DocumentMeta {...meta}>
      {/* // <div className={clsx(classes.newRoot)}> */}
      <div
        className={clsx(
          (open === true && hover === true) ||
            (open === true && hover === false)
            ? classes.root
            : classes.newRoot
        )}
      >
        {props.StartCall === false && (
          <>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <div
                  className={clsx("tb_imageContainer", {
                    [classes.hideNew]: open,
                  })}
                >
                  <img src={logoWhite} alt="" />
                </div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <div style={{ flexGrow: 1 }}></div>
                {/* <IconButton color="inherit">
                <Notifications
                  fontSize="small"
                  className="tb_notificationIcon"
                />
              </IconButton> */}
                <div
                  className="tb_profileContainer"
                  onClick={(e) => popUpHandler(true, e)}
                >
                  <p className="tb_para1">{doctorInfo?.doctorName}</p>
                  <div className="tb_imageContainer1">
                    <img
                      src={
                        doctorInfo?.doctorImage
                          ? doctorInfo.doctorImage
                          : // ? doctorInfo?.doctorImage
                            profile
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={
                      profilePopUp ? "tb_profilePopUp" : "tb_profilePopUp_hide"
                    }
                  >
                    <span
                      class="material-icons tb_ClearIcon"
                      onClick={(e) => popUpHandler(false, e)}
                    >
                      clear
                    </span>
                    <div className="tb_imageContainer1">
                      <img
                        src={
                          doctorInfo?.doctorImage
                            ? doctorInfo.doctorImage
                            : // ? doctorInfo?.doctorImage
                              profileBlack
                        }
                        alt=""
                      />
                    </div>
                    <p className="tb_Name">{doctorInfo?.doctorName}</p>
                    <hr />
                    <p className="tb_para2" onClick={(e) => logoutHandler(e)}>
                      Logout
                    </p>
                  </div>
                </div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpenNew}
                  edge="start"
                  className={clsx(classes.menuButtonNew)}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer
              onMouseOver={handlerMouseOver}
              onMouseOut={handlerMouseOut}
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <div className="tdc_logoContainer">
                  <div className="tdc_imageContainer">
                    <img src={logoImage} alt="" />
                  </div>
                  <div className="tdc_logoContent">
                    <p className="tdc_p1">Zebra Doctor</p>
                    <p className="tdc_p2">Medical Stack</p>
                  </div>
                </div>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                {["Dashboard", "Help"].map((text, index) => (
                  <ListItem onClick={() => Tabchange(text)} button key={text}>
                    <ListItemIcon className={classes.iconStyle}>
                      {index % 2 === 0 ? <Dashboard /> : <Help />}
                    </ListItemIcon>
                    <ListItemText
                      className={classes.textStyle}
                      primary={text}
                    />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        )}
        <main
          className={
            props.StartCall === false ? classes.content : classes.content1
          }
        >
          {props.StartCall === false && <div className={classes.toolbar} />}
          {Tab == "Dashboard" ? (
            <TelemedDoctorAuthContainer
              logout={logout}
              socket={props.socket}
              cookies={props.cookies}
              doctorName={doctorInfo?.doctorName}
            />
          ) : Tab == "Help" ? (
            <HelpCenter />
          ) : null}
        </main>
      </div>
    </DocumentMeta>
  );
}

const mapStateToProps = (state) => {
  console.log("====================================");
  console.log(state);
  console.log("====================================");
  return {
    doctor: state.TelemedicineDocotorAuthReducer.doctor,
    telemedicineDoctor_Auth_Status:
      state.TelemedicineDocotorAuthReducer.telemedicineDoctor_Auth_Status,
    StartCall: state.TelemedOpenmodal.StartCall,
    authOpen: state.TelemedicineDocotorAuthReducer.authOpen,
    isPermissionchecked: state.TelemedOpenmodal.isPermissionchecked,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutDoctor: (data) => {
      DoctorEndServer.logoutDoctor(data);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TelemedDoctorContainer);
