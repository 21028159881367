import React, { Suspense, lazy, Component } from "react";
// import "./App.css";

//Telemed VideoCAll
///
import { Route, withRouter, Switch } from "react-router-dom";
// import NewForm from "./components/NewAppointmentForm/BookAnAppointment";
import Loader from "./components/PracticeSetup/common/Loader";
import "./style.scss";
import { withCookies } from "react-cookie";
import { initializeFirebase } from "./push-notification";
import GoogleAnalyticsManager from "./components/googleAnalyticsManager/GoogleAnalyticsManager";

// import LandingPage_Main from "./components/LandingPage/LandingPage_Main";
import TelemedPatient from "./components/Telemedicine/TelemedPatientEnd/index";
import io from "socket.io-client";
import { ROOT_URL } from "./constants/config";
import TelemedDoctorContainer from "./components/Telemedicine/TelemedDoctorEndNewDesign/TelemedDoctorContainer/TelemedDoctorContainer";

// const covidtest = lazy(() =>
//   import("./components/NewAppointmentForm/covid-test")
// );
// const Veterinary = lazy(() =>
//   import("./components/NewAppointmentForm/Veterinary-Care-CenterForm")
// );
// const INTAKEFORM = lazy(() => import("./components/INTAKEFORM"));

// const Questionaire = lazy(() =>
//   import("./components/NewAppointmentForm/BookAnAppointment")
// );
const socket = io.connect(
  `${ROOT_URL}`,
  
  { transports: ["websocket"] }
  ,
  {
    secure: true
  }
);
// const TelemedDoctorContainer = lazy(() =>
//   import(
//     "./components/Telemedicine/TelemedDoctorEndNewDesign/TelemedDoctorContainer/TelemedDoctorContainer"
//   )
// );
// const TelemedPatient = lazy(() =>
//   import("./components/Telemedicine/TelemedPatientEnd/index")
// );
document.addEventListener("visibilitychange", () =>
  socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
);

window.addEventListener("focus", () =>
  socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
);
window.addEventListener("blur", () =>
  socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
);


class App extends Component {
  componentWillMount() {
    initializeFirebase();
  }
  // componentDidMount() {
  //   Axios.get(`${ROOT_URL}/api/client/fixUrls`)
  //     .then((res) => {
  //       console.log("res........", res.data);
  //     })
  //     .catch((err) => {
  //       console.log("err......", err);
  //     });
  // }

  render() {
    window.addEventListener("focus", () =>
      socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
    );
    window.addEventListener("blur", () =>
      socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
    );
    document.addEventListener("visibilitychange", () =>
      socket.connect(`${ROOT_URL}`, { transports: ["websocket"] })
    );
    console.log("socket_DARAPROPS",this.props);
    console.log("socket_DARA",socket);
    // console.log("TEST :", this.props);
    
    // console.log("CHECKINGTYPEOF", typeof JSON.parse("sadasd"),JSON.parse("sadasd"));
    
    
    return (
      <div>
        <GoogleAnalyticsManager GATrackingCode="UA-139627195-1" />
        <Switch>
          <Route
            exact
            path="/doctor"
            render={() => (
              <TelemedDoctorContainer
                socket={socket}
                cookies={this.props.cookies}
              />
            )}
          />

          <Route
            exact
            path="/telemed/:Roomname"
            render={() => (
              <TelemedPatient socket={socket} cookies={this.props.cookies} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(withCookies(App));
