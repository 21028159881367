import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "../../../../servers/Telemed";
import { bindActionCreators } from "redux";
import MaskedInput from "react-text-mask";
// import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../../../../css/components/_TelemedDoctoeAuth.scss";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import camera from "../../../../images/tda_camera.png";
import Tick from "../../../../images/Group-1599.png";
import { Button, CircularProgress } from "@material-ui/core";
import { ROOT_URL } from "../../../../constants/config";
import store from "../../../../store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
// import {use} from "react-cookie"
class TelemedDocotorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Token: "",
      Email: "",
      emailFlag: false,
      emailErrorMsg: "Enter valid email",
      phone: "",
      phoneFlag: false,
      phoneErrorMsg: "Oops! Try xxx-xxx-xxxx",
      Loader: false,
      otherForm: true,
      requestTokenStatus: "",
      doctorAuthError:""
    };
  }
  onCloseModal = () => {
    let { dispatch } = this.props;
    // dispatch(Actions.OpenModal(false));
  };
  otherFormHandler = (otherForm) => {
    this.setState({
      otherForm,
      Token: "",
      Email: "",
      emailFlag: false,
      phone: "",
      phoneFlag: false,
      requestTokenStatus: "",
      doctorAuthError:""
    });
  };
  Cantclose = () => {
    // alert("Authenticate the token");
  };
  Submit = async () => {
    let { dispatch } = this.props;

    const config = {
      Token: this.state.Token,
    };

    this.props.authenticateDoctor(this.state.Token, this.state.Email);
    if (this.props.Status == "TELEMEDICINEDOCTOR_AUTH_SUCCESS") {
      alert("Dsvds");
    }
  };
  Onchage = (e) => {
    this.setState({
      // Token: e.target.value,

      [e.target.name]: e.target.value,
    });
  };

  emailHandler = (e) => {
    const { value } = e.target;
    const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,100}$/;
    const validateEmail = reg.test(value);
    if (validateEmail) {
      this.setState({
        Email: value,
        emailFlag: false,
        requestTokenStatus: "",
      });
    } else if (value === "") {
      this.setState({
        Email: value,
        emailFlag: false,
        requestTokenStatus: "",
      });
    } else {
      this.setState({
        Email: value,
        emailFlag: true,
        requestTokenStatus: "",
      });
    }
  };

  phoneHandler = (e, data, eventr, formatedvalue) => {
    const { value } = formatedvalue;
    // const reg = /^(\d{3})-(\d{3})-(\d{4})$/;
    // const validatePhone = reg.test(value);
    if (!e) {
      this.setState({
        phone: formatedvalue,
        phoneFlag: true,
        requestTokenStatus: "",
      });
    } else if (formatedvalue === "") {
      this.setState({
        phone: formatedvalue,
        phoneFlag: true,
        requestTokenStatus: "",
      });
    } else {
      this.setState({
        phone: formatedvalue,
        phoneFlag: false,
        requestTokenStatus: "",
      });
    }
  };

  componentDidUpdate(prevprops) {
    if (this.props.authOpen !== prevprops.authOpen) {
      this.setState({
        Token: "",
        Email: "",
      });
    }
    if (prevprops !== this.props) {
      console.log("updated");
      this.setState({
        doctorAuthError:this.props.error
      })
    }

  }

  requestTokenHandler = () => {
    var data = {
      doctorEmail: this.state.Email,
      doctorPhone: this.state.phone,
    };
    let Token = this.props.cookies.get("token");
    let practiceID = this.props.cookies.get("practiceID");

    let objetct = {
      token: Token,
      practiceID: practiceID,
    };
    const myThis = this;
    this.setState({ Loader: true });
    fetch(ROOT_URL + "/api/admin/Doctors/AutheTokenMessage", {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.stringify(objetct),
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(function (response) {
      if (response.state >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json().then((data) => {
        console.log("Response from database: ", data.body);
        if (data.AuthToken_MessageStatus === "failure") {
          return myThis.setState({
            requestTokenStatus: "failure",
            Loader: false,
          });
        } else if (data.AuthToken_MessageStatus === "success") {
          return myThis.setState({
            requestTokenStatus: "success",
            Loader: false,
            // phone: "",
            // phoneFlag: false,
            // Email: "",
            // emailFlag: false,
          });
        } else if (
          data.AuthToken_MessageStatus === "Email or Phone Number is Invalid"
        ) {
          return myThis.setState({
            requestTokenStatus: "invalid",
            Loader: false,
          });
        }
      });
    });
  };

  render() {
    console.log("dsvsdfrgv",this.state.doctorAuthError, this.props.authOpen);

    // const socket = io('http://localhost');
    let { dispatch } = this.props;

    console.log("====================================", this.props);
    return (
      <div>
        <Snackbar
          autoHideDuration={5000}
          open={this.props.ExpireSnackBar}
          onClose={() => dispatch(Actions.ExpireSnackBar(false))}
        >
          <MuiAlert
            severity="warning"
            onClose={() => dispatch(Actions.ExpireSnackBar(false))}
            elevation={6}
            variant="filled"
          >
            Your Session has been Expired
          </MuiAlert>
        </Snackbar>

        <Modal
          open={this.props.authOpen}
          showCloseIcon={false}
          onClose={this.Cantclose}
          className="tda_modal"
          center
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Fade in={this.props.authOpen}>
            {this.state.otherForm ? (
              <div className="tda_modal_content">
                <div className="tda_sec1">
                  <p className="tda_sec1_p1">Televisit</p>
                  <p className="tda_sec1_p2">Login to your Televisit room</p>
                  <div className="tda_sec1_imageContainer">
                    <img src={camera} alt="" />
                  </div>
                </div>

                <form action="" className="tda_form">
                  <TextField
                    value={this.state.Email}
                    name="Email"
                    onChange={this.emailHandler}
                    id="outlined-basic"
                    label="Email *"
                    variant="outlined"
                    type="email"
                    className="main-input-s"
                    onKeyPress={
                      this.state.Email == "" ||
                      this.state.emailFlag ||
                      this.state.Token == ""
                        ? ""
                        : (event) => {
                            if (event.key === "Enter") {
                              this.Submit();
                            }
                          }
                    }
                  />
                  <span
                    className="tda_span1"
                    style={{
                      opacity:
                        this.state.email === "" || this.state.emailFlag ? 1 : 0,
                    }}
                  >
                    {this.state.emailErrorMsg}
                  </span>
                  <TextField
                    value={this.state.Token}
                    name="Token"
                    onChange={this.Onchage}
                    id="outlined-basic"
                    label="Token *"
                    variant="outlined"
                    type="password"
                    style={{ marginTop: 10 }}
                    className="main-input-s"
                    onKeyPress={
                      this.state.Email == "" ||
                      this.state.emailFlag ||
                      this.state.Token == ""
                        ? ""
                        : (event) => {
                            if (event.key === "Enter") {
                              this.Submit();
                            }
                          }
                    }
                  />
                  <span
                    className="tda_span1"
                    style={{
                      opacity: this.state.doctorAuthError? 1 : 0,
                    }}
                  >
                    {this.state.doctorAuthError}
                  </span>
                  {/* <span
                    className="tda_span1"
                    style={{
                      opacity: this.props.error == "Disable Doctor" ? 1 : 0,
                    }}
                  >
                    Sorry ! Not Allow to login since your practice has marked
                    your profile as disable.
               
                  </span> */}

                  <Button
                    className={
                      this.state.Token === "" ||
                      this.state.Email === "" ||
                      this.state.emailFlag
                        ? "tda_btn1_disable"
                        : "tda_btn1"
                    }
                    onClick={this.Submit}
                    // style={{ opacity: this.state.Token == "" ? 0.5 : 1 }}
                    disabled={
                      this.state.Token === "" ||
                      this.state.Email === "" ||
                      this.state.emailFlag
                        ? "disabled"
                        : ""
                    }
                  >
                    {this.props.Status == "TELEMEDICINEDOCTOR_AUTH_LOADING" ? (
                      <CircularProgress
                        style={{ height: 30, width: 30, color: "#fff" }}
                      />
                    ) : (
                      "Login"
                    )}
                    {/* Login */}
                  </Button>
                  {/* <div
                className="FormLoader"
                style={{ position: "relative", top: 24, left: 90 }}
              >
                {this.props.Status == "TELEMEDICINEDOCTOR_AUTH_LOADING" ? (
                  <Loader />
                ) : (
                  ""
                )}
              </div> */}
                  <p className="tda_form_p1">
                    Can't access the room?{" "}
                    <span
                      className="tda_form_sp1"
                      onClick={() => this.otherFormHandler(false)}
                    >
                      Request Authentication Token
                    </span>
                  </p>
                </form>
                {/* <h5>Request an authentication token</h5> */}
              </div>
            ) : (
              <div className="tda_modal_content">
                <div className="tda_sec1">
                  <p className="tda_sec1_p1">Televisit</p>
                  <p className="tda_sec1_p2">Login to your Televisit room</p>
                  <div className="tda_sec1_imageContainer">
                    <img src={camera} alt="" />
                  </div>
                </div>

                <form action="" className="tda_form">
                  {this.state.requestTokenStatus === "success" ? (
                    <>
                      <div className="tda_modal_imageContainer">
                        <img src={Tick} alt="" />
                      </div>
                      <p className="tda_form_p1 tda_form_p3">
                        We have sent you a token on your registered number. If
                        you haven't received the token, click on resend to
                        request again.
                      </p>
                      <Button
                        className="tda_btn1"
                        onClick={() =>
                          this.setState({ requestTokenStatus: "" })
                        }
                      >
                        {this.state.Loader ? (
                          <CircularProgress
                            style={{ height: 30, width: 30, color: "#fff" }}
                          />
                        ) : (
                          "Resend"
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <TextField
                        value={this.state.Email}
                        name="Email"
                        onChange={this.emailHandler}
                        id="outlined-basic"
                        label="Email *"
                        variant="outlined"
                        type="email"
                        className="main-input-s"
                        onKeyPress={
                          this.state.Email == "" ||
                          this.state.emailFlag ||
                          this.state.phone == "" ||
                          this.state.phoneFlag
                            ? ""
                            : (event) => {
                                if (event.key === "Enter") {
                                  this.requestTokenHandler();
                                }
                              }
                        }
                      />
                      <span
                        className="tda_span1"
                        style={{
                          opacity:
                            this.state.email === "" || this.state.emailFlag
                              ? 1
                              : 0,
                        }}
                      >
                        {this.state.emailErrorMsg}
                      </span>
                      {/* <TextField
                        value={this.state.phone}
                        name="phone"
                        onChange={this.phoneHandler}
                        id="outlined-basic"
                        label="Phone Number *"
                        variant="outlined"
                        style={{ marginTop: 10 }}
                        className="main-input-s"
                        InputProps={{
                          inputComponent: phoneMaskCustom,
                        }}
                        onKeyPress=
                      {this.state.Email == "" ||
                      this.state.emailFlag ||
                      this.state.phone == "" ||
                      this.state.phoneFlag
                        ? ""
                        : (event) => {
                            if (event.key === "Enter") {
                              this.requestTokenHandler();
                            }
                          }}
                        /> */}
                      <div className="IntrnationalPhoneInput">
                        <PhoneInput
                          className="main-input-s"
                          placeholder="Phone"
                          value={this.state.phone}
                          country={"us"}
                          onChange={this.phoneHandler}
                          autoFormat={true}
                          onKeyPress={
                            this.state.Email == "" ||
                            this.state.emailFlag ||
                            this.state.phone == "" ||
                            this.state.phoneFlag
                              ? ""
                              : (event) => {
                                  if (event.key === "Enter") {
                                    this.requestTokenHandler();
                                  }
                                }
                          }
                        />
                      </div>
                      {this.state.requestTokenStatus === "invalid" ? (
                        <span
                          className="tda_span1"
                          style={{
                            opacity:
                              this.state.requestTokenStatus == "invalid"
                                ? 1
                                : 0,
                          }}
                        >
                          Email or Phone-number is invalid
                        </span>
                      ) : (
                        <span
                          className="tda_span1"
                          style={{
                            opacity: this.state.phoneFlag ? 1 : 0,
                          }}
                        >
                          {this.state.phoneErrorMsg}
                        </span>
                      )}
                      <Button
                        className={
                          this.state.phone === "" ||
                          this.state.phoneFlag ||
                          this.state.Email === "" ||
                          this.state.emailFlag
                            ? "tda_btn1_disable"
                            : "tda_btn1"
                        }
                        onClick={this.requestTokenHandler}
                        disabled={
                          this.state.phone === "" ||
                          this.state.phoneFlag ||
                          this.state.Email === "" ||
                          this.state.emailFlag
                            ? "disabled"
                            : ""
                        }
                      >
                        {this.state.Loader ? (
                          <CircularProgress
                            style={{ height: 30, width: 30, color: "#fff" }}
                          />
                        ) : (
                          "Request Token"
                        )}
                      </Button>
                    </>
                  )}

                  <p className="tda_form_p1 tda_form_p2">
                    Have you received the token?{" "}
                    <span
                      className="tda_form_sp1"
                      onClick={() => this.otherFormHandler(true)}
                    >
                      Login
                    </span>
                  </p>
                </form>
              </div>
            )}
          </Fade>
        </Modal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    doctor: state.TelemedicineDocotorAuthReducer.doctor,
    authOpenw: state.TelemedicineDocotorAuthReducer.authOpen,
    Status: state.TelemedicineDocotorAuthReducer.telemedicineDoctor_Auth_Status,
    ExpireSnackBar: state.TelemedOpenmodal.ExpireSnackBar,
  };
})(TelemedDocotorAuth);

function phoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
}
