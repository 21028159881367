import React from "react";
import { ReactMic } from "react-mic";
let TestMic = false;
export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      TestMic: false,
    };
  }

  startRecording = () => {
    this.setState({ record: true });
  };

  stopRecording = () => {
    this.setState({ record: false });
  };

  onData = (recordedBlob) => {
    // this.state.TestMic = true;
    this.setState({
      TestMic: true,
    });
    // console.log("chunk of real-time data is: ", recordedBlob);
  };

  onStop(recordedBlob) {
    console.log("recordedBlob is: ", recordedBlob);
  }

  componentDidMount() {
    this.setState({ record: true });
  }
  render() {
    return (
      <React.Fragment>
        <div className="graph">
          <ReactMic
            visualSetting="sinewave"
            record={this.state.record}
            className="sound-wave"
            //   onStop={this.onStop}
            mimeType="audio/webm"
            bitRate={256000} // defaults -> 128000 (128kbps).  React-Mic-Gold only.
            sampleRate={96000} // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
            timeSlice={3000}
            
            onData={this.onData}
            strokeColor="#FD1CAF"
            backgroundColor="white"
          />
          {/* <button onClick={this.startRecording} type="button">
          Start
        </button>

        <button onClick={this.stopRecording} type="button">
          Stop
        </button> */}
        </div>
        <div style={{ width: "60%" }}>
          {this.state.TestMic ? (
            <p style={{ color: "#38BF85" }}>
              {" "}
              Your mic is working. you’re good to go!
            </p>
          ) : (
            <p style={{ color: "#ED316B" }}>
              You’re mic is not working. Please check if your microphone is
              connected.
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}
