import React from "react";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import Axios from "axios";
import { ROOT_URL } from "../../constants/config";
import store from "../../store";

function index(props) {
  console.log("====================================");
  console.log("axyz", props.cookies);
  console.log("====================================");

  setInterval(() => {
    Exp();
  }, 8.64e7);
  const Exp = () => {
    let Token = props.cookies.get("token");
    let practiceID = props.cookies.get("practiceID");
    let objetct = {
      token: Token,
      practiceID: practiceID,
    };
    Axios.post(
      `${ROOT_URL}/api/admin/practices/CheckJWTxpirySession`,
      {},
      {
        headers: {
          Authorization: JSON.stringify(objetct),
        },
      }
    ).then((res) => {
      if (res.data.expire == true) {
        let accessToken = props.cookies.get("token");
        var object = { token: accessToken };
        Axios.post(`${ROOT_URL}/api/admin/practice/logout`, object).then(
          (res) => {
            store.dispatch({ type: "SessionExpired", payload: true });
            props.cookies.remove("practiceAccount", { path: "/" });
            props.cookies.remove("isUserLoggedIn", { path: "/" });
            props.cookies.remove("token", { path: "/" });
            props.cookies.remove("practiceID", { path: "/" });
            props.cookies.remove("WebToken", { path: "/" });
            props.history.push("/medstack");
            window.location.reload();
          }
        );
      }
    });
  };

  return <></>;
}

export default withRouter(withCookies(index));
