import React, { Component } from "react";
import "./Style.scss";
import TableHeader from "./TableHeader";
import TableList from "./TableList";
import VeterinaryTableList from "./VeterinaryTableList";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SeachValue: "",
    };
  }

  SearchDoctor = (v) => {
    console.log(v);
    this.setState({
      SeachValue: v,
    });
  };

  render() {
    const coockiesData = this.props.cookies.get("practiceAccount");
    const selectPractice =
      coockiesData?.practiceID.Select ||
      coockiesData?.practiceID.Select?.practiceTypeId?.practiceTypeId;
      console.log("AppoinmentDataProps",this.props.AppoinmentData);
    return (
      <>
        <TableHeader SearchDoctor={this.SearchDoctor} />
        {selectPractice === "Veterinary Practice" || selectPractice == 2 ? (
          <VeterinaryTableList
            SeachValue={this.state.SeachValue}
            AppoinmentData={this.props.AppoinmentData}
            startcall={this.props.startcall}
            cancelAppointmentHandler={this.props.cancelAppointmentHandler}
            ToggleConfirmationModal={this.props.ToggleConfirmationModal}
            selectedIndex={this.props.selectedIndex}
            disconnectSocket={this.props.disconnectSocket}
            onCallEnd={this.props.onCallEnd}
            doctorName={this.props.doctorName}
          />
        ) : (
          <TableList
            SeachValue={this.state.SeachValue}
            AppoinmentData={this.props.AppoinmentData}
            startcall={this.props.startcall}
            cancelAppointmentHandler={this.props.cancelAppointmentHandler}
            ToggleConfirmationModal={this.props.ToggleConfirmationModal}
            selectedIndex={this.props.selectedIndex}
            disconnectSocket={this.props.disconnectSocket}
            onCallEnd={this.props.onCallEnd}
          />
        )}
      </>
    );
  }
}

export default index;
