import React, { useState, useEffect, useRef } from "react";
import "../../css/components/_TelemdVideoCall.scss";
import ImageProfile from "../../images/profile-01-2.png";
import Video from "../../images/Icon-awesome-video.png";
import callend from "../../images/Iconmaterial-call-end.png";
import VoiceMute from "../../images/Icon-ionic-md-mic.png";
import CrossVideo from "../../images/no-video.png";
import unmuteImg from "../../images/mute-122-474987.png";
import * as Action from "../../servers/Telemed";
import { useSelector } from "react-redux";
import { ROOT_URL } from "../../constants/config";
import Permissionvideo from "../Telemedicine/TelemedDoctorEnd/DrCameraPermession";
import io from "socket.io-client";
import moment from "moment";
import Axios from "axios";
import MedicalPracticeDetails from "./MedicalPracticeDetails";
import VeterinaryPracticeDetails from "./VeterinaryPracticeDetails";
import Privacy from "./PrivacyTest";
const Participant = ({
  participant,
  dispatch,
  muteMycamera,
  MuteCamera,
  muteMyMic,
  MuteMic,
  OnDisconnect,
  MuteVideeo,
  socket,
  roomName,
  Muteed,
  cookies,
  // socket
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [appDetail, setAppDetail] = useState(false);
  const [browser, setSetbrowser] = useState("");

  // const DrMuteOption = useSelector(
  //   (state) => state.TelemedOpenmodal.DrMuteToPatient
  // );

  const PatientData = useSelector(
    (state) => state.TelemedOpenmodal.SelectData?.data
  );

  const [Mutee, setMute] = useState(null);
  const [MuteVideo, setVideo] = useState();
  const [DrMute, setDrMute] = useState();
  const [Muteedoption, setMuteedOption] = useState(null);
  const videoRef = useRef();
  const audioRef = useRef();
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);
  useEffect(() => {
    const audioTrack = audioTracks[0];
    console.log("dsfdsfdsf", audioTrack, audioTrack);

    if (audioTrack) {
      console.log("dsfdsfdsf", audioTrack, audioTrack.kind);

      if (audioTrack.attach) {
        console.log("dsfdsfdsf666", audioTrack, audioTrack.attach);

        audioTrack.attach(audioRef.current);
      }
      return () => {
        // console.log("dsfdsfdsf66677", audioTrack, audioTrack.detach);
        if (audioTrack.detach !== undefined)
          audioTrack.detach();
        else
          OnDisconnect();


      };
    }
  }, [audioTracks]);
  const EndCall = () => {
    OnDisconnect();
    //dispatch(Action.StartCAll(false));
    // Axios.post(`${ROOT_URL}/AppoinmentEnd`, { id: Id }).then((res) => {
    //   console.log("====================================");
    //   console.log(res.data);unmuteImg
    //   console.log("====================================");
    // });
  };
  React.useEffect(() => {
    socket.on("CallMuteStaticImg", (data) => {
      console.log("Muteeddd:", data);
      setMuteedOption(data.staticImgShow);
    });
  }, [socket]);
  const Mute = () => {
    muteMyMic(MuteMic);

    // const socket = io(`${ROOT_URL}`);
    // socket.on("PatientEndMute", (data) => {
    //   console.log("socketdata", data);
    //   setMute(data.Mute);
    // });
    // socket.emit("Mute", {
    //   Mute:
    //     Mutee == null
    //       ? false
    //       : Mutee == true
    //       ? false
    //       : Mutee == false
    //       ? true
    //       : null,
    // });
    // dispatch(
    //   Action.DrMuteToPatient(
    //     DrMuteOption == false ? true : DrMuteOption == true ? false : ""
    //   )
    // );
    // setMute(Mutee == "false" ? "true" : Mutee == "true" ? "false" : "");
  };
  // console.log(JSON.stringify(Mutee));
  const PatiendendMuteVideo = () => {
    // setMuteed("Show-patinet-end-Static-img")
    Muteed();
    if (MuteCamera == false) {
      socket.emit("ShowstaticImg", { CallMuteRoomname: roomName, Mute: null });
    }
    // if (Muteed == "Show-patinet-end-Static-img") {
    //   socket.emit("ShowstaticImg", { CallMute: "Dr" })

    // } else {
    //   setMuteed("")
    //   socket.emit("ShowstaticImg", { CallMute: "Patient" })
    // }

    // this.props.socket()
    // socket.on("PatientVideocallmute", (data) => {
    //   setVideo(data.PatinetVideoMute);
    // });
    muteMycamera(MuteCamera);
    // socket.emit("VideoCallMutePatient", {
    //   PatinetVideoMute:
    //     MuteVideo == null
    //       ? false
    //       : MuteVideo == true
    //       ? false
    //       : MuteVideo == false
    //       ? true
    //       : null,
    // });
  };
  console.log("ddaffMuted:", Muteed);

  // const socket = io(`${ROOT_URL}`);
  // React.useEffect(() => {
  //   socket.on("Drmute", (data) => {
  //     console.log();

  //     setDrMute(data.DrMute);
  //   });
  // }, [socket]);

  const appDetailHandler = (appDetail) => {
    setAppDetail(appDetail);
  };
  const coockiesData = cookies.get("practiceAccount");
  const selectPractice =
    coockiesData?.practiceID?.Select ||
    coockiesData?.practiceID?.practiceTypeId?.practiceTypeId;

  console.log("appointmentData....", PatientData);

  useEffect(() => {
    myFunction();
  }, []);
  const myFunction = () => {
    // if (
    //   (navigator.userAgent.indexOf("Opera") ||
    //     navigator.userAgent.indexOf("OPR")) != -1
    // ) {
    //   alert("Opera");
    // }
    if (navigator.userAgent.indexOf("Chrome") != -1) {
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      setSetbrowser("Safari");

      // alert("Safari");
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    }
    // else if (
    //   navigator.userAgent.indexOf("MSIE") != -1 ||
    //   !!document.documentMode == true
    // ) {
    //   //IF IE > 10
    //   alert("IE");
    // }
    else {
      // alert("unknown");
    }
  };

  return (
    <React.Fragment>
      <div
        className="VideoSection"
        style={{ backgroundColor: "black", zIndex: 100 }}
      >
        {browser == "Safari" ? (
          <Privacy Mute={Mute} PatiendendMuteVideo={PatiendendMuteVideo} />
        ) : (
          ""
        )}

        <video
          // autoplay="autoplay"
          loop="loop"
          ref={videoRef}
          autoPlay={false}
          preload="auto"
        />
        <audio ref={audioRef} autoPlay={false} muted={DrMute} />
        <div className="Profile">
          {Muteedoption == false ? (
            <img src={ImageProfile} alt="" />
          ) : Muteedoption == true ? (
            <img src={ImageProfile} alt="" />
          ) : (
            ""
          )}
          {/* <p>Raze</p> */}
        </div>
        <div className="Buttons">
          <button
            onClick={() => {
              Mute();
            }}
            className="btn1"
          >
            {MuteMic == true ? (
              <img src={VoiceMute} alt="" />
            ) : (
              <img
                style={{ height: 30, width: 30 }}
                src={unmuteImg}
                alt="dsv"
              />
            )}
          </button>{" "}
          <button onClick={() => EndCall()} className="btn1 EndCall">
            <img src={callend} alt="" />
          </button>{" "}
          <button onClick={() => PatiendendMuteVideo()} className="btn1">
            {MuteCamera == true ? (
              <img src={Video} alt="" />
            ) : (
              <img src={CrossVideo} />
            )}
          </button>
        </div>
        {/* <div className="participent_cam">
          <Permissionvideo />
        </div> */}
        {PatientData &&
          (selectPractice === "Veterinary Practice" || selectPractice == 2 ? (
            <VeterinaryPracticeDetails
              appDetailHandler={appDetailHandler}
              PatientData={PatientData}
              appDetail={appDetail}
            />
          ) : (
            <MedicalPracticeDetails
              appDetailHandler={appDetailHandler}
              PatientData={PatientData}
              appDetail={appDetail}
            />
          ))}
      </div>
    </React.Fragment>
  );
};

export default Participant;
