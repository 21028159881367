import React, { Component } from "react";
import "../../../css/components/_Helpcenter.scss";
import Menubar from "./MenuBar";
import TextField from "@material-ui/core/TextField";
export default class HelpCenter extends Component {
  render() {
    return (
      <div className="containeer">
        <h1>Help Center</h1>
        <div className="QuestionBox">
          <h4>Frequently Asked Questions</h4>
          <div className="Question-num">
            <h3>How can I sign up?</h3>
            <p>
              If you're a medical service provider, you can sign into you
              telemedicine room using and authentication token provided to you
              by the admin. If you're a patient,
              <br /> you'll simply go to your doctor's room url and enter your
              email. If the appointment is under that email, you'll be prompted
              into the telemedicine room.
            </p>
          </div>
          <div className="Question-num">
            <h3>Is there a time limit per call?</h3>
            <p>
              No, there is no time limit per call. A call can be ended whenever
              required.
            </p>
          </div>
          <div className="Question-num">
            <h3>Will my call be recorded?</h3>
            <p>
              No, we respect your privacy and therefore none of your calls will
              be recorded.
            </p>
          </div>
          <div className="Question-num">
            <h3>Can I reschedule my telemedicine appointment?</h3>
            <p>
              Right now you cant, but we're working on this feature and will be
              available for use soon.
            </p>
          </div>
        </div>

        {/* <div className="Troubleshoot">
                    <h5>Troubleshoot</h5>
                    <div className="typeQues" >
                        <input type="email" placeholder="Email" name="" id="" />
                        <textarea placeholder="Question" name="" id="" cols="30" rows="10"></textarea>
                        <div className="btn-submit" >
                            <button>Submit</button>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}
