export const TelemedicineDoctor_Auth_Actions= {
	
    TelemedicineDoctor_Auth:
	{
		GET : "TELEMEDICINEDOCTOR_AUTH_GET",
		FAILURE: "TELEMEDICINEDOCTOR_AUTH_FAILURE",
		SUCCESS:"TELEMEDICINEDOCTOR_AUTH_SUCCESS",
		LOADING:"TELEMEDICINEDOCTOR_AUTH_LOADING"

    },
}
export const telemedicineDoctor_Auth_Status ={
    TelemedicineDoctor_Auth:
	{
		NEW: "TELEMEDICINEDOCTOR_AUTH_NEW",
		SUCCESS:"TELEMEDICINEDOCTOR_AUTH_SUCCESS",
		FAILURE:"TELEMEDICINEDOCTOR_AUTH_FAILURE",
		LOADING:"TELEMEDICINEDOCTOR_AUTH_LOADING"
    }
}