import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { IconButton, Button } from "@material-ui/core";
import MicTest from "./ReactMictest";

import { Cancel } from "@material-ui/icons";
import "./style.scss";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(2px)",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    outline: "none",
    width: "60%",
    height: "400px",
    borderRadius: 16,
    overflow: "hidden",
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.OpenMicTest}
        id="Parent_test_modal"
        onClose={() => props.OpenTestCallMicModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.OpenMicTest}>
          <div id="TestModal" className={classes.paper}>
            <div className="header">
              <p>Mic Test</p>
              <IconButton onClick={() => props.OpenTestCallMicModal(false)}>
                <Cancel style={{ color: "white" }} />
              </IconButton>
            </div>
            <div className="TestCallGraph">
              <h1>
                {" "}
                <span> Say</span> “Hello”{" "}
              </h1>
              <p>
                Click the microphone icon and record your audio to test your mic
              </p>

              <MicTest />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
