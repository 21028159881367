import React, { Component } from "react";
import * as Actions from "../../../servers/Telemed";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../../../css/components/_TelemedMainDashBoard.scss";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import img from "../../../images/profile-0.png";
import Img1 from "../../../images/small-down.png";
import Axios from "axios";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      Token: "",
      Data: {},
      Message: "",
    };
    const { dispatch } = props;
    this.boundActionCreators = bindActionCreators(Actions, dispatch);
  }
  //   onCloseModal = () => {
  //     let { dispatch } = this.props;
  //     dispatch(Actions.OpenModal(false));
  //   };

  dropdownHandler = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };

  render() {
    return (
      <div className="Header">
        <div className="HeadingHeadr">
          <h1>
            Zebra Doctor <span>Medical Stack</span>
          </h1>
        </div>

        <div className="Avatar" onClick={this.dropdownHandler}>
          <p>
            {this.props.Show == true ? (
              <React.Fragment>
                {this.props.PatientName.TokenData !== undefined
                  ? this.props.PatientName.TokenData.patientFirstName
                  : ""}
              </React.Fragment>
            ) : (
              ""
            )}
          </p>
          {/* <img src={Img1} alt="Down" /> */}
          <img className="profile" src={img} alt="profile" />
          {this.state.dropdown && (
            <div
              className="dropdown"
              onClick={() => {
                this.props.logout();
                this.dropdownHandler();
              }}
            >
              <button className="d_field">Log Out</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("====================================");
  console.log(state);
  console.log("====================================");
  return {
    ...state,
    PatientName: state.TelemedOpenmodal.patientDataEmailAfterverify,
    Show: state.TelemedOpenmodal.Open,
  };
};
export default connect(mapStateToProps)(Dashboard);
