export function OpenModal(Open) {
  console.log("====================================");
  console.log(Open);
  console.log("====================================");
  return {
    type: "OpenModal",
    payload: Open,
  };
}
export function AuthTokenData(data) {
  return {
    type: "AuthData",
    payload: data,
  };
}
export const DrAuthTOkenVerify = (data) => {
  return {
    type: "DrAuthTOkenVerify",
    payload: data,
  };
};
export const StartCAll = (Start, data, SelectedData) => {
  return {
    type: "StartCall",
    payload: Start,
    Data: data,
    SelectedData: SelectedData,
  };
};
export const StartCAllForPatientend = (Start) => {
  return {
    type: "StartCAllForPatientend",
    payload: Start,
  };
};
export const patientDataEmailAfterverify = (data) => {
  return {
    type: "patientDataEmailAfterverify",
    payload: data,
  };
};

export const DrDataFromPatinet = (data) => {
  return {
    type: "DrDataFromPatinet",
    payload: data,
  };
};

export const DrMuteToPatient = (Mute) => {
  return {
    type: "DrMuteToPatient",
    payload: Mute,
  };
};
export const PatientMuteToDr = (Mute) => {
  return {
    type: "PatientMuteToDr",
    payload: Mute,
  };
};

export const DrId = (ID) => {
  return {
    type: "DrID",
    payload: ID,
  };
};
export const RoomTokenExpire = (Expire) => {
  return {
    type: "RoomTokenExpire",
    payload: Expire,
  };
};

export const isPermissionchecked = (check) => {
  return {
    type: "isPermissionchecked",
    payload: check,
  };
};
export const Patientend_Block_Send_message_to_Dr = (check) => {
  return {
    type: "Patientend_Block_Send_message_to_Dr",
    payload: check,
  };
};
export const ExpireSnackBar = (check) => {
  return {
    type: "ExpireSnackBar",
    payload: check,
  };
};
