import Axios from "axios";
import { ROOT_URL } from "../../../constants/config";
import store from "../../../store/index";
import { TelemedicineDoctor_Auth_Actions } from "./TelemedDoctorAuth/TelemedDoctorAuth_constants";

export const DoctorEndServer = {
  authenticateDoctor: authenticateDoctor,
  logoutDoctor: logoutDoctor,
  doctorCreateRoom: doctorCreateRoom,
  ExpireTime: ExpireTime,
};

function logoutDoctor(cookies) {
  // console.log("dsvsdvsdv", cookies.cookies.get("doctor"))
  // console.log("dsvsdvsdv", cookies.cookies.remove("doctor", { path: "/doctor" }));
  // cookies.cookies.remove("doctor", { path: "/doctor" });
  localStorage.removeItem("drid", { path: "/doctor" });
  localStorage.removeItem("DrExpireToken", { path: "/doctor" });
  // cookies.removeCookie("doctor")

  store.dispatch({
    type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.GET,
  });
}

function ExpireTime(cookies) {
  cookies.cookies.remove("doctor", { path: "/doctor" });
  // store.dispatch({ type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.GET })
  return { type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.GET };
}

function authenticateDoctor(data, Email) {
  console.log("dsvds", data, Email);
  //User login Server function
  store.dispatch({
    type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.LOADING,
  });
  let getData = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    // body: JSON.stringify({
    //   Token:
    //     data == ""
    //       ? data.Drid == true
    //         ? data.Token.authToken
    //         : "sa"
    //       : data.Drid == true
    //       ? data.Token.authToken
    //       : data,
    //   doctorEmail:
    //     Email == ""
    //       ? data.Drid == true
    //         ? data.Token.doctorEmail
    //         : "sa"
    //       : data.Drid == true
    //       ? data.Token.doctorEmail
    //       : Email,
    // }),
    
  };
  const body={
    Token:
        data == ""
          ? data.Drid == true
            ? data.Token.authToken
            : "sa"
          : data.Drid == true
          ? data.Token.authToken
          : data,
      doctorEmail:
        Email == ""
          ? data.Drid == true
            ? data.Token.doctorEmail
            : "sa"
          : data.Drid == true
          ? data.Token.doctorEmail
          : Email,
  }
  var errormsg = "Server response error. Please try again !";
  Axios.post(ROOT_URL + "/AuthToken",body)
    // .then((response) => response.json())
    .then((responseJson) => {
      console.log("rdsvsdvdsv", responseJson);
      if (
        responseJson != null &&
        responseJson != undefined &&
        responseJson.message !== "Not found" &&
        responseJson.message !== "Disable Doctor"
      ) {
        console.log("conditionPassif");
        let now = new Date();
        if (localStorage.getItem("DrId")) {
          localStorage.clear();
          localStorage.setItem(
            "DrExpireToken",
            JSON.stringify({ data: responseJson, Time: now.getTime() })
          );
          localStorage.setItem("DrId", JSON.stringify(responseJson.data.doctor));
          store.dispatch({
            type:
              TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.SUCCESS,
            payload: responseJson,
          });
        } else {
          localStorage.setItem("DrId", JSON.stringify(responseJson.data.doctor));
          localStorage.setItem(
            "DrExpireToken",
            JSON.stringify({ data: responseJson, Time: now.getTime() })
          );
          store.dispatch({
            type:
              TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.SUCCESS,
            payload: responseJson,
          });
        }

        // this.props.cookies.set("doctor", responseJson.TokenData, { path: "/doctor" });
        // dispatch(Actions.DrAuthTOkenVerify(responseJson.data));

        store.dispatch({
          type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.SUCCESS,
          payload: responseJson,
        });
      } else {
        console.warn("Error: ", responseJson);

        //  alert(JSON.stringify(responseJson))
        store.dispatch({
          type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.FAILURE,
          payload: responseJson,
        });
      }
    })
    .catch((error) => {
      console.log("here", error.response.data.message);
      errormsg = "Connection Problem: " + error;
      store.dispatch({
        type: TelemedicineDoctor_Auth_Actions.TelemedicineDoctor_Auth.FAILURE,
        payload: error.response.data.message,
      });
    });
}

async function doctorCreateRoom(doctor) {
  console.log(doctor);
  const config = {
    DrId: doctor._id,
    identity: doctor.fullName,
    room: doctor.roomName,
  };
  const data = await fetch(ROOT_URL + "/video/token", {
    method: "POST",
    body: JSON.stringify(config),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
  console.log(data);
}
